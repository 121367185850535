import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from './userApi';
import { getSocket } from './socket';

interface UserState {
	isAuthenticated: boolean;
	userData: {
		userId: string;
		firstName: string;
		lastName: string;
		email: string;
	} | null;
	isIntegrated: boolean;
}

const initialState: UserState = {
	isAuthenticated: false,
	userData: null,
	isIntegrated: false,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAuthenticated: (state, action: PayloadAction<boolean>) => {
			state.isAuthenticated = action.payload;
		},
		setUserData: (state, action: PayloadAction<UserState['userData']>) => {
			state.userData = action.payload;
		},
		logout: (state) => {
			state.isAuthenticated = false;
			state.userData = null;
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			getSocket()?.disconnect();
		},
		setIsIntegrated: (state, action: PayloadAction<boolean>) => {
			state.isIntegrated = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			userApi.endpoints.login.matchFulfilled,
			(state, { payload }) => {
				state.isAuthenticated = true;
				// state.userData = payload.user;
			}
		);
		builder.addMatcher(
			userApi.endpoints.me.matchFulfilled,
			(state, { payload }) => {
				state.userData = payload;
			}
		);
	},
});

export const { setAuthenticated, setUserData, logout, setIsIntegrated } =
	userSlice.actions;
export default userSlice.reducer;
