import {useMemo, useEffect, useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {incrementMonth, decrementMonth, setFilter} from '../calendarSlice';
import {setAndFormatEndDate, setAndFormatStartDate} from '../calendarUtils';
import {RootState} from '../../../store/rootReducer';
import {groupDaysByWeek, groupDaysByMonth} from '../calendarUtils';
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';
import styles from './Carousel.module.scss';
import {ReactComponent as LeftIcon} from 'components/icons/chevron_left.svg';
import {ReactComponent as RightIcon} from 'components/icons/chevron_right.svg';

export default function Carousel({
	activeDays,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	mode = 'calendar',
	selectDay,
	newWidth,
}: {
	activeDays?: string[];
	startDate: number | null;
	endDate: number | null;
	setStartDate: (date: number | null | undefined) => void;
	setEndDate: (date: number | null | undefined) => void;
	mode?:
		| 'calendar'
		| 'responsive'
		| 'simple'
		| 'simple responsive'
		| 'integrated'
		| 'week';
	selectDay: (day: number) => void;
	newWidth: boolean;
}) {
	const carouselRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const {days} = useSelector((state: RootState) => state.calendar);
	const startModify = useSelector(
		(state: RootState) => state.calendar.startModify
	);
	const endModify = useSelector((state: RootState) => state.calendar.endModify);

	const months = useMemo(() => {
		const sortedDaysByWeek = groupDaysByWeek(days);
		const sortedDaysByMonth = groupDaysByMonth(sortedDaysByWeek);
		return sortedDaysByMonth;
	}, [days]);

	const monthLowerCase = format(new Date(months[1][1][1]), 'MMMM', {
		locale: fr,
	});
	const month =
		monthLowerCase.charAt(0).toUpperCase() + monthLowerCase.slice(1);

	const year = format(new Date(months[1][1][1]), 'yyyy', {locale: fr});

	function containsFirstDayOfMonth(dates: number[]) {
		return dates.some((date) => {
			const testDate = new Date(date);
			return testDate.getDate() === 1;
		});
	}

	const needIncrement = useRef(true);
	const needDecrement = useRef(false);
	const stopScroll = useRef(false);

	const handleScroll = useCallback(
		(
			e: // scroll event
			Event
		) => {
			if (carouselRef.current) {
				const {scrollLeft, clientWidth, scrollWidth} = carouselRef.current;

				if (mode === 'week') {
					const previousMonthWidth = containsFirstDayOfMonth(months[0][4])
						? containsFirstDayOfMonth(months[1][4])
							? 336 * 3
							: 336 * 4
						: 336 * 3;

					const currentMonthWidth = containsFirstDayOfMonth(months[1][4])
						? 336 * 4
						: 336 * 5;

					if (scrollLeft === previousMonthWidth) {
						dispatch(decrementMonth());
						needDecrement.current = true;
						stopScroll.current = true;

						carouselRef.current.style.overflow = 'hidden';
						setTimeout(() => {
							if (carouselRef.current)
								carouselRef.current.style.overflow = 'auto';
						}, 0);
					} else if (scrollLeft === scrollWidth - currentMonthWidth) {
						dispatch(incrementMonth());
						needIncrement.current = true;
						stopScroll.current = true;

						carouselRef.current.style.overflow = 'hidden';
						setTimeout(() => {
							if (carouselRef.current)
								carouselRef.current.style.overflow = 'auto';
						}, 0);
					} else {
						// prevents scroll inertia
						if (scrollLeft < previousMonthWidth) {
							if (stopScroll.current) {
								stopScroll.current = false;
								return;
							}
							carouselRef.current.scrollLeft = previousMonthWidth;
							carouselRef.current.style.overflow = 'hidden';
							setTimeout(() => {
								if (carouselRef.current)
									carouselRef.current.style.overflow = 'auto';
							}, 0);
						} else if (scrollLeft > scrollWidth - currentMonthWidth) {
							carouselRef.current.scrollLeft = scrollWidth - currentMonthWidth;
							carouselRef.current.style.overflow = 'hidden';
							setTimeout(() => {
								if (carouselRef.current)
									carouselRef.current.style.overflow = 'auto';
							}, 0);
						}
					}
				} else {
					if (
						scrollLeft === scrollWidth - clientWidth ||
						scrollLeft === scrollWidth - clientWidth - 2
					) {
						dispatch(incrementMonth());
						carouselRef.current.scrollLeft = clientWidth;
					} else if (scrollLeft === 0) {
						dispatch(decrementMonth());
						carouselRef.current.scrollLeft = clientWidth;
					}
				}
			}
		},
		[dispatch, months, mode]
	);

	useEffect(() => {
		const container = carouselRef?.current;

		if (container) {
			container.scrollLeft = container.clientWidth;
			container.addEventListener('scroll', handleScroll);
			if (mode === 'week' && needIncrement.current) {
				const currentMonthWidth = containsFirstDayOfMonth(months[1][4])
					? 336 * 3
					: 336 * 4;
				carouselRef.current.scrollLeft = currentMonthWidth + 350;
				needIncrement.current = false;
			} else if (mode === 'week' && needDecrement.current) {
				const currentMonthWidth = containsFirstDayOfMonth(months[0][4])
					? containsFirstDayOfMonth(months[1][4])
						? 336 * 7
						: 336 * 9
					: 336 * 7;
				carouselRef.current.scrollLeft = currentMonthWidth;
				needDecrement.current = false;
			}
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [carouselRef, handleScroll, mode, months]);

	const scrollToNextMonth = (direction: boolean) => {
		if (carouselRef.current) {
			carouselRef.current.scrollTo({
				left: carouselRef.current.scrollLeft + (direction ? 350 : -350),
				behavior: 'smooth',
			});
		}
	};

	const handleClickOnDay = (day: number) => {
		dispatch(setFilter(''));

		if (mode.includes('simple')) {
			setAndFormatStartDate(
				setStartDate,
				setEndDate,
				endDate,
				new Date(day).toLocaleDateString(),
				false
			);
			return;
		}

		if (mode === 'week' || mode === 'integrated') {
			selectDay(day);
		}

		if (day === startDate) {
			setAndFormatStartDate(setStartDate, setEndDate, endDate, '', false);
		} else if (day === endDate && startDate) {
			setAndFormatEndDate(setStartDate, setEndDate, startDate, '', false);
		} else if (!startDate) {
			setAndFormatStartDate(
				setStartDate,
				setEndDate,
				endDate,
				new Date(day).toLocaleDateString(),
				false
			);
		} else if (!endDate) {
			setAndFormatEndDate(
				setStartDate,
				setEndDate,
				startDate,
				new Date(day).toLocaleDateString(),
				false
			);
		} else if (startModify) {
			setAndFormatStartDate(
				setStartDate,
				setEndDate,
				endDate,
				new Date(day).toLocaleDateString(),
				false
			);
		} else if (endModify) {
			setAndFormatEndDate(
				setStartDate,
				setEndDate,
				startDate,
				new Date(day).toLocaleDateString(),
				false
			);
		} else if (day < startDate) {
			setAndFormatEndDate(
				setStartDate,
				setEndDate,
				startDate,
				new Date(day).toLocaleDateString(),
				false
			);
		} else if (day < endDate) {
			setAndFormatStartDate(
				setStartDate,
				setEndDate,
				endDate,
				new Date(day).toLocaleDateString(),
				false
			);
		} else {
			setAndFormatEndDate(
				setStartDate,
				setEndDate,
				startDate,
				new Date(day).toLocaleDateString(),
				false
			);
		}
	};

	const getContainerStyle = (day: number, monthDate: number) => {
		return `${styles.container} ${
			new Date(day).getMonth() !== new Date(monthDate).getMonth() &&
			mode !== 'week'
				? styles.notDisplayed
				: ''
		} ${
			activeDays?.includes(new Date(day).toLocaleDateString())
				? styles.active
				: ''
		}`;
	};

	const getGapStyle = (
		day: number,
		monthDate: number,
		leftPosition: boolean
	) => {
		if (
			startDate &&
			endDate &&
			day > startDate &&
			day < endDate &&
			new Date(day).getDay() !== (leftPosition ? 1 : 0) &&
			mode !== 'week' &&
			mode !== 'integrated'
		)
			return `${styles.gap} ${styles[leftPosition ? 'left' : 'right']}`;
		if (
			new Date(day).getMonth() === new Date(monthDate).getMonth() &&
			mode !== 'week' &&
			mode !== 'integrated'
		)
			return `${styles.gap} ${styles.disabled}`;
		if (
			startDate &&
			endDate &&
			day > startDate &&
			day < endDate &&
			mode !== 'week' &&
			mode !== 'integrated'
		)
			return `${styles.gap}`;
		return `${styles.gap} ${styles.disabled}`;
	};

	const getDayStyle = (day: number) => {
		return `${styles.day}  ${
			startDate && endDate && day > startDate && day < endDate
				? mode !== 'week' && mode !== 'integrated'
					? styles.betweenDates
					: ''
				: ''
		} ${
			(day === startDate || day === endDate) &&
			mode !== 'week' &&
			mode !== 'integrated'
				? styles.selected
				: ''
		}`;
	};

	return (
		<div className={`${styles.calendar} ${styles[mode]}`}>
			<div className={styles.header}>
				<div className={styles.date}>
					{month} {year}
				</div>
				<div className={styles.orderButtons}>
					<button onClick={() => scrollToNextMonth(false)}>
						<LeftIcon />
					</button>
					<button onClick={() => scrollToNextMonth(true)}>
						<RightIcon />
					</button>
				</div>
			</div>
			<div className={`${styles.days} ${newWidth ? styles.newWidth : ''}`}>
				<div className={styles.day}>Lu</div>
				<div className={styles.day}>Ma</div>
				<div className={styles.day}>Me</div>
				<div className={styles.day}>Je</div>
				<div className={styles.day}>Ve</div>
				<div className={styles.day}>Sa</div>
				<div className={styles.day}>Di</div>
			</div>

			<div
				ref={carouselRef}
				className={`${styles.carousel} ${
					newWidth ? styles.updateTransform : ''
				}`}
			>
				{months.map((month, monthIndex) => (
					<div
						key={monthIndex}
						style={{flexDirection: mode === 'week' ? 'row' : 'column'}}
						className={styles.month}
					>
						{month.map((week, weekIndex) => (
							<div key={weekIndex} className={styles.week}>
								{(mode !== 'week' ||
									weekIndex <
										(containsFirstDayOfMonth(months[1][4]) ? 4 : 5)) &&
									week.map((day, dayIndex) => {
										const monthDate = months[monthIndex][1][1];
										return (
											<div
												key={dayIndex}
												className={getContainerStyle(day, monthDate)}
											>
												<div className={getGapStyle(day, monthDate, true)} />
												<div className={getGapStyle(day, monthDate, false)} />
												<div
													onClick={() => handleClickOnDay(day)}
													className={getDayStyle(day)}
												>
													<div
														className={
															day === new Date().setHours(0, 0, 0, 0)
																? styles.today
																: styles.empty
														}
													>
														{new Date(day).getDate()}
													</div>
													{new Date(day).getDate()}
													{activeDays?.includes(
														new Date(day).toLocaleDateString()
													) && <div className={styles.badge} />}
												</div>
											</div>
										);
									})}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
}
