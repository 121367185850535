import React from 'react';
import styles from './LoadingFullScreen.module.scss';
import { ReactComponent as LogoIcon } from '../icons/soopcl.svg';

export default function LoadingFullScreen() {
	return (
		<div className={styles.loadingFullScreen}>
			<LogoIcon />
			Chargement ...
		</div>
	);
}
