import React, {useState, useEffect, useRef} from 'react';
import {useGetAnswerQuery} from '../../store/api';
import {renderToString} from 'react-dom/server';
import {Checklist as ChecklistType} from '../../sharedTypes';
import {ReactComponent as EyeIcon} from '../icons/eye.svg';
import {ReactComponent as DownloadIcon} from '../icons/upload.svg';
import PdfReport from './PdfReport';
import config from '../../config';
import styles from './PdfReport.module.scss';
import {useDeviceType} from 'useDeviceType';

const baseUrl = config.baseUrl;

const HiddenPdfReport: React.FC<{
	response: any;
	answers: any;
	setSummaryPages: React.Dispatch<React.SetStateAction<any[][]>>;
}> = ({response, answers, setSummaryPages}) => {
	return (
		<div style={{position: 'absolute', left: '-9999px', top: '-9999px'}}>
			<PdfReport
				response={response}
				answers={answers}
				setSummaryPages={setSummaryPages}
				initialSummaryPages={[]}
			/>
		</div>
	);
};

export default function PdfGenerator({
	response,
	id,
}: {
	response: {
		state: ChecklistType;
		timestamp: string;
		responseId: string;
		modifiedBy: {
			userId: string;
			lastName: string;
			firstName: string;
			timestamp: string;
		}[];
	};
	id: string;
}) {
	const {isTypeOrLarger} = useDeviceType();
	const [visualizePdf, setVisualizePdf] = useState(false);
	const [loading, setLoading] = useState(false);
	const {data} = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});
	const [summaryPages, setSummaryPages] = useState<any[][]>([]);
	const hiddenReportRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (data && hiddenReportRef.current) {
			// Force a reflow to ensure correct calculations
			hiddenReportRef.current.style.display = 'block';
			setTimeout(() => {
				hiddenReportRef.current!.style.display = 'none';
			}, 0);
		}
	}, [data]);

	const handleDownload = async () => {
		try {
			setLoading(true);

			if (!data) {
				throw new Error('No data to generate PDF');
			} else {
				const htmlContent = renderToString(
					<PdfReport
						response={response}
						answers={data}
						setSummaryPages={setSummaryPages}
						initialSummaryPages={summaryPages}
					/>
				);
				const pdfResponse = await fetch(`${baseUrl}/pdf/generate`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					body: JSON.stringify({html: htmlContent}),
				});

				if (!pdfResponse.ok) {
					throw new Error('Erreur lors de la génération du PDF');
				}

				const pdfBlob = await pdfResponse.blob();
				const url = window.URL.createObjectURL(pdfBlob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'rapport.pdf');
				document.body.appendChild(link);
				link.click();
				link.parentNode?.removeChild(link);
			}
		} catch (error) {
			console.error('Erreur lors du téléchargement du PDF :', error);
		} finally {
			setLoading(false);
		}
	};

	const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setVisualizePdf(false);
		}
	};

	return (
		<div>
			{isTypeOrLarger('md') && (
				<div className={styles.flex}>
					<button
						className={styles.greyButton}
						onClick={handleDownload}
						disabled={loading}
					>
						<DownloadIcon />
						{loading ? 'Génération...' : 'Télécharger le PDF'}
					</button>
					<button
						className={styles.greyButton}
						onClick={() => setVisualizePdf(true)}
					>
						<EyeIcon />
						Visualiser le PDF
					</button>
				</div>
			)}

			{data && (
				<div ref={hiddenReportRef}>
					<HiddenPdfReport
						response={response}
						answers={data}
						setSummaryPages={setSummaryPages}
					/>
				</div>
			)}

			{visualizePdf && (
				<div onClick={handleBackgroundClick} className={styles.darkBackground}>
					<div
						className={styles.pdfContainer}
						onClick={(e) => e.stopPropagation()}
					>
						{' '}
						{data && (
							<PdfReport
								response={response}
								answers={data}
								hasSpacing
								setSummaryPages={setSummaryPages}
								initialSummaryPages={summaryPages}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
