import styles from './CoverImage.module.scss';

const colors = [
	'#FAB1A0',
	'#A29BFE',
	'#7FE1D9',
	'#A4B2D5',
	'#3CCEDC',
	'#FD67B1',
	'#FAA95E',
	'#8BABF1',
	'#DC92AD',
	'#8AB85D',
	'#90D8B2',
	'#E396DF',
	'#ED8795',
	'#00BF7D',
	'#8C6583',
	'#D3C96C',
	'#B884D1',
	'#52B5B4',
	'#5D5BBD',
	'#E77A6C',
];

function hashStringToNumber(str: any) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

function getColorForId(id: any) {
	const hash = hashStringToNumber(String(id));
	const colorIndex = Math.abs(hash) % colors.length;
	return colors[colorIndex];
}

export default function CoverImage({
	id,
	coverImage = '',
	small = false,
	big = false,
	width = '',
	height = '',
}: {
	id?: string | number;
	coverImage?: string;
	small?: boolean;
	big?: boolean;
	width?: string;
	height?: string;
}) {
	const bgColor = getColorForId(id);

	return (
		<div
			className={`${styles.coverImage} ${small ? styles.small : ''} ${
				big ? styles.big : ''
			} ${coverImage !== '' ? styles.noBg : ''}`}
			style={{
				...(coverImage === '' && { backgroundColor: bgColor }),
				...(width !== '' &&
					height !== '' && {
						width,
						height,
					}),
			}}
		>
			{coverImage !== '' ? (
				<img
					src={coverImage}
					alt="Checklist cover"
					className={styles.fitImage}
				/>
			) : null}
		</div>
	);
}
