import React, {useRef, useEffect, useState, useMemo, useCallback} from 'react';
import {useNavigation} from '../../NavigationContext';
import {getSocket} from '../../store/socket';
import {formatTimeDifference} from '../../utils';
import {ReactComponent as PlusIcon} from '../icons/plus.svg';
import {ReactComponent as PlayIcon} from '../icons/play_circle_filled.svg';
import {
	Response,
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
} from '../../sharedTypes';
import DraggableList from '../DraggableList';
import styles from './DraggableNavigation.module.scss';
import Avatar from '../Avatar/Avatar';
import CoverImage from 'components/shared/CoverImage/CoverImage';
import DeletePopup from './DeletePopup';
import {useDeviceType} from 'useDeviceType';

export default function DraggableNavigation({
	firstResponse,
	checklistId,
	title,
	checklist,
	setCommentMode,
}: {
	firstResponse?: Response;
	checklistId: string;
	title: string;
	checklist: ChecklistType;
	setCommentMode: () => void;
}) {
	const {isTypeOrLarger} = useDeviceType();
	const {navigation, setNavigation, setOpenedResponse, setResponseMode} =
		useNavigation();
	const [updatesCount, setUpdatesCount] = useState(0);
	const [pendingCreation, setPendingCreation] = useState<
		'chapter' | 'question' | null
	>(null);
	const {chapters: chaptersData} = checklist;
	const chapters = useMemo(() => {
		return chaptersData as (ChapterType & {questions: QuestionType[]})[];
	}, [chaptersData]);

	const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
	const [heights, setHeights] = useState<number[]>([]);

	const navigationContentRef = useRef<HTMLDivElement>(null);

	const items = useMemo(() => {
		const flatItems: Array<{
			type: 'chapter' | 'question';
			data: ChapterType | QuestionType;
		}> = [];

		chapters?.forEach((chapter) => {
			flatItems.push({type: 'chapter', data: chapter});
			chapter.questions?.forEach((question) => {
				if (
					navigation !== 'checklist' &&
					navigation.includes(chapter.chapterId)
				) {
					flatItems.push({type: 'question', data: question});
				}
			});
		});

		return flatItems;
	}, [chapters, navigation]);

	const order = useMemo(() => {
		const chapterMap = new Map<string, number>();
		let currentChapterOrder = 0;

		const sortedChapters = [...chapters].sort((a, b) => a.index - b.index);

		sortedChapters.forEach((chapter, i) => {
			chapterMap.set(chapter.chapterId, currentChapterOrder);
			currentChapterOrder++;
		});

		return items
			.map((item, i) => {
				if (item.type === 'chapter') {
					return {
						originalIndex: i,
						order: chapterMap.get((item.data as ChapterType).chapterId) ?? 0,
					};
				} else {
					const question = item.data as QuestionType;
					const chapterOrder = chapterMap.get(question.chapterId) ?? 0;
					return {
						originalIndex: i,
						order: chapterOrder + question.index / 100,
					};
				}
			})
			.sort((a, b) => a.order - b.order)
			.map((item) => item.originalIndex);
	}, [chapters, items]);

	useEffect(() => {
		itemRefs.current = itemRefs.current.slice(0, items.length);
		while (itemRefs.current.length < items.length) {
			itemRefs.current.push(null);
		}
	}, [items]);

	useEffect(() => {
		setTimeout(() => {
			setHeights(
				items.map((_, index) => {
					return itemRefs.current[index]?.offsetHeight || 0;
				})
			);
		}, 50);
	}, [items, updatesCount, navigation]);

	function findMovedElement(arr1: number[], arr2: number[]) {
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				if (arr1[i + 1] === arr2[i]) {
					return arr1[i];
				} else if (arr1[i] === arr2[i + 1]) {
					return arr2[i];
				}
			}
		}
	}

	function arraysEqual(arr1: number[], arr2: number[]) {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	}

	const handleReorderItems = (newOrder: number[]) => {
		if (arraysEqual(order, newOrder)) return;
		if (navigation.startsWith('checklist')) {
			const chapterOrders = newOrder.map(
				(index) =>
					(
						chapters.find(
							(chapter: ChapterType) =>
								chapter.index ===
								chapters.map((chapter: ChapterType) => chapter.index)[index]
						) as any
					)?.chapterId
			);
			getSocket()?.emit('reorderChapters', {
				checklistId,
				chapterOrders,
			});
		} else {
			const orderedItems = order.map((orderIndex) => items[orderIndex]);
			const reorderedItems = newOrder.map((orderIndex) => items[orderIndex]);
			let movedIndex = findMovedElement(order, newOrder) || 0;
			if (items[movedIndex].type === 'chapter') {
				movedIndex++;
			}

			if (newOrder.indexOf(movedIndex) === 0) {
				setHeights(
					items.map((_, index) => {
						return itemRefs.current[index]?.offsetHeight || 0;
					})
				);
				return;
			}

			let currentChapter = null;
			let targetChapter = null;

			for (let i = order.indexOf(movedIndex); i > -1; i--) {
				if (orderedItems[i].type === 'chapter') {
					currentChapter = orderedItems[i].data;
					break;
				}
			}

			for (let i = newOrder.indexOf(movedIndex); i > -1; i--) {
				if (reorderedItems[i].type === 'chapter') {
					targetChapter = reorderedItems[i].data;
					break;
				}
			}

			const questionOrders = [];
			let isInOriginalChapter = false;
			for (let i = 0; i < reorderedItems.length; i++) {
				const currentItem = reorderedItems[i];
				if (
					currentItem.type === 'chapter' &&
					currentItem.data?.chapterId === currentChapter?.chapterId
				) {
					isInOriginalChapter = true;
				} else if (currentItem.type === 'chapter') {
					isInOriginalChapter = false;
				}

				if (isInOriginalChapter && currentItem.type === 'question') {
					questionOrders.push((currentItem.data as QuestionType).questionId);
				}
			}

			const targetQuestionOrders: string[] = [];
			let isInTargetChapter = false;
			for (let i = 0; i < reorderedItems.length; i++) {
				const currentItem = reorderedItems[i];
				if (
					currentItem.type === 'chapter' &&
					currentItem.data?.chapterId === targetChapter?.chapterId
				) {
					isInTargetChapter = true;
				} else if (currentItem.type === 'chapter') {
					isInTargetChapter = false;
				}

				if (isInTargetChapter && currentItem.type === 'question') {
					targetQuestionOrders.push(
						(currentItem.data as QuestionType).questionId
					);
				}
			}

			const targetChapterIncludesQuestion = (
				targetChapter as ChapterType
			)?.questions?.find(
				(question) => question.questionId === targetQuestionOrders[0]
			);
			if (targetQuestionOrders.length === 1 && !targetChapterIncludesQuestion) {
				(targetChapter as ChapterType)?.questions?.forEach((question) => {
					targetQuestionOrders.push(question.questionId);
				});
				setNavigation(
					`question_${targetChapter?.chapterId}_${targetQuestionOrders[0]}`
				);
			}

			const reorderPayload = {
				checklistId,
				chapterId: currentChapter?.chapterId,
				targetChapterId: targetChapter?.chapterId,
				questionOrders,
				targetQuestionOrders,
			};

			if (reorderPayload.chapterId === reorderPayload.targetChapterId) {
				reorderPayload.targetChapterId = '';
				reorderPayload.targetQuestionOrders = [];
			}

			// if (reorderPayload.questionOrders.length > 0) {
			getSocket()?.emit('reorderQuestions', reorderPayload);
			// }
		}
	};

	const handleCreateQuestion = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();

		if (
			navigation.startsWith('chapter_') ||
			navigation.startsWith('question_')
		) {
			setPendingCreation('question');
			setUpdatesCount(updatesCount + 1);
			getSocket()?.emit('createQuestion', {
				checklistId,
				chapterId: navigation.split('_')[1],
				title: '',
				type: null,
				useChapterContext: true,
			});
		}
	};

	const handleCreateChapter = () => {
		setPendingCreation('chapter');
		getSocket()?.emit('createChapter', {
			checklistId,
			title: ``,
			context: '',
		});
	};

	const scrollToElement = useCallback(
		(chapterId: string, nav: string) => {
			setNavigation(nav);
			setTimeout(() => {
				const element = itemRefs.current.find(
					(el) => el?.getAttribute('data-chapter-id') === chapterId
				);
				if (element) {
					element.scrollIntoView({behavior: 'smooth'});
				}
			}, 100);
		},
		[setNavigation]
	);

	useEffect(() => {
		const handleCreateResponse = (response: {
			questionId: string;
			chapterId: string;
		}) => {
			if (pendingCreation === 'chapter' && !response.questionId) {
				setNavigation(`chapter_${response.chapterId}`);
				setPendingCreation(null);
			} else if (pendingCreation === 'question' && response.questionId) {
				setNavigation(`question_${response.chapterId}_${response.questionId}`);
				setPendingCreation(null);
			}
		};

		getSocket()?.on('chapterCreated', handleCreateResponse);
		getSocket()?.on('questionCreated', handleCreateResponse);

		return () => {
			getSocket()?.off('chapterCreated', handleCreateResponse);
			getSocket()?.off('questionCreated', handleCreateResponse);
		};
	}, [pendingCreation, setNavigation]);

	const [lastModifiedTime, setLastModifiedTime] = useState(
		formatTimeDifference(
			new Date(),
			new Date(checklist.lastModified.timestamp || new Date())
		)
	);

	useEffect(() => {
		setLastModifiedTime(
			formatTimeDifference(
				new Date(),
				new Date(checklist.lastModified.timestamp || new Date())
			)
		);

		const intervalId = setInterval(() => {
			setLastModifiedTime(
				formatTimeDifference(
					new Date(),
					new Date(checklist.lastModified.timestamp || new Date())
				)
			);
		}, 10000);

		return () => clearInterval(intervalId);
	}, [checklist.lastModified.timestamp]);

	useEffect(() => {
		if (!isTypeOrLarger('md') && firstResponse) {
			setOpenedResponse(firstResponse || null);
			setResponseMode(true);
		}
	}, [firstResponse, isTypeOrLarger, setOpenedResponse, setResponseMode]);

	return (
		<div className={styles.draggableNavigation}>
			<div className={styles.navigationContainer}>
				<div className={styles.navButtons}>
					<button
						onClick={() => {
							setOpenedResponse(firstResponse || null);
							setResponseMode(true);
						}}
					>
						Réponses
					</button>
					<button className={styles.selected}>Éditer la Checklist</button>
				</div>
				<div
					onClick={() => {
						setNavigation('checklist');
						setCommentMode();
					}}
					className={styles.navigationHeader}
				>
					<CoverImage id={checklistId} coverImage={checklist?.coverImage} />
					<div className={styles.column}>
						<h5>{title}</h5>
						<div className={styles.lastEdit}>
							<Avatar
								small
								name={`${checklist.lastModified.user?.firstName} ${checklist.lastModified.user?.lastName}`}
							/>
							{lastModifiedTime === '0 seconde'
								? "modifié à l'instant"
								: `modifié il y a ${lastModifiedTime}`}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.navigationContent} ref={navigationContentRef}>
				<DraggableList
					items={items.map((item, i) => {
						if (item.type === 'chapter') {
							const chapter = item.data as ChapterType;
							return (
								<div
									ref={(el) => {
										itemRefs.current[i] = el;
									}}
									data-chapter-id={chapter.chapterId}
									key={chapter.chapterId}
									className={`${styles.element} `}
									onClick={() => {
										if (navigation.includes(chapter.chapterId)) {
											setNavigation(`checklist_${checklistId}`);
										} else {
											scrollToElement(
												chapter.chapterId,
												`chapter_${chapter.chapterId}`
											);
										}
									}}
								>
									<div
										className={`${styles.chapter} ${
											navigation.includes(chapter.chapterId)
												? styles.selected
												: ''
										}`}
									>
										<div className={styles.title}>
											<div className={styles.subtitle}>
												Chapitre{' '}
												{(
													chapters.find(
														(c) => c.chapterId === chapter.chapterId
													) || {
														index: 0,
													}
												).index + 1}
											</div>
											{chapter.title === ''
												? 'Nouveau chapitre'
												: chapter.title}
										</div>
										<div
											onClick={handleCreateQuestion}
											className={styles.arrowIcon}
										>
											{navigation.includes(chapter.chapterId) ? (
												<PlusIcon />
											) : null}
										</div>
									</div>
								</div>
							);
						} else {
							const question = item.data as QuestionType;
							return (
								<div
									ref={(el) => {
										itemRefs.current[i] = el;
									}}
									key={question.questionId}
									className={`${styles.element}`}
									onClick={() =>
										setNavigation(
											`question_${question.chapterId}_${question.questionId}`
										)
									}
								>
									<div className={styles.question}>
										<div
											className={`${styles.questionContent} ${
												navigation.includes(question.questionId)
													? styles.selected
													: ''
											}`}
										>
											{navigation.includes(question.questionId) && <PlayIcon />}
											<div className={styles.spacedFlex}>
												<div className={styles.title}>
													{question.title === ''
														? 'Nouvelle question'
														: question.title}
													<div className={styles.subtitle}>
														{question.type === 'text'
															? 'Texte'
															: question.type === 'multipleSelect'
															? 'Choix multiple'
															: question.type === 'singleSelect'
															? 'Choix unique'
															: question.type === 'number'
															? 'Nombre'
															: question.type === 'date'
															? 'Date'
															: question.type === 'media'
															? 'Média'
															: 'Non défini'}
													</div>
												</div>
												{navigation ===
													`question_${question.chapterId}_${question.questionId}` && (
													<DeletePopup
														checklistId={checklistId}
														question={question}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							);
						}
					})}
					itemsData={items}
					undraggableItems={
						navigation.startsWith('checklist')
							? []
							: [...chapters.map((chapter) => `${chapter.chapterId}`)]
					}
					burgerIcon={[...chapters.map((chapter) => `${chapter.chapterId}`)]}
					noIcon={[
						...chapters
							?.map((chapter) =>
								chapter.questions?.map((question) => question.questionId)
							)
							.flat(),
					]}
					order={order}
					setOrder={(newOrder) => handleReorderItems(newOrder)}
					heights={heights}
					disableInitialAnimation
					translateY={1.65}
				/>
				<div
					className={styles.blankSpace}
					style={{
						height: `${
							(navigationContentRef.current?.clientHeight || 0) - 55
						}px`,
					}}
				/>
			</div>
			<div className={styles.navigationFooter}>
				<button className={styles.noBorderButton} onClick={handleCreateChapter}>
					<PlusIcon />
					Nouveau chapitre
				</button>
			</div>
		</div>
	);
}
