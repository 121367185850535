import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { api } from './api';
import { userApi } from './userApi';
import { socketMiddleware } from './socketMiddleware';

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(api.middleware)
			.concat(userApi.middleware)
			.concat(socketMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
