import {useState, useRef, useEffect} from 'react';
import Switch from '../../Switch/Switch';
import {Question as QuestionType} from '../../../sharedTypes';
import {ReactComponent as CloseIcon} from '../../icons/close.svg';
import styles from './Settings.module.scss';

const MAX_CHARACTERS = 30;

export default function TextSettings({
	question,
	updateQuestion,
}: {
	question: QuestionType;
	updateQuestion: (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => void;
}) {
	const [input, setInput] = useState('');
	const inputRef = useRef<HTMLInputElement>(null);
	const widthRef = useRef<HTMLSpanElement>(null);

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter' && input) {
			setInput('');
			updateQuestion(question, {
				text: {
					criticalEnabled: question.text?.criticalEnabled ? true : false,
					criticalValues: [...(question.text?.criticalValues || []), input],
				},
			});
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInput(e.target.value);
	};

	const handleFocusOnInput = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	useEffect(() => {
		if (widthRef.current && inputRef.current) {
			inputRef.current.style.width = `${widthRef.current.offsetWidth + 10}px`;
		}
	}, [input]);

	return (
		<div className={styles.textSettings}>
			<div
				onClick={() => {
					updateQuestion(question, {
						text: {
							criticalEnabled: !question.text?.criticalEnabled,
							criticalValues: question.text?.criticalValues || [],
						},
					});
				}}
				className={styles.lateralPannelSection}
			>
				<div className={styles.spacedFlex}>
					<div className={styles.button}>RÉPONSES CRITIQUES</div>
					<Switch isOn={question.text?.criticalEnabled} />
				</div>
				<p>
					Permet d’indiquer les réponses problématiques. Ajouter les mots ou
					expressions indicateurs d’une réponse critique dans le champ dédié.
					<br />
					<br />
					Si cette ou ces réponses sont données, elles seront mises en avant
					lorsque la Checklist aura été complétée.
				</p>
			</div>
			{question.text?.criticalEnabled && (
				<div className={styles.criticalContainer}>
					<div className={styles.column}>
						<div className={styles.title}>Contient un des mots suivants : </div>
						<div className={styles.subtitle}>
							Appuyer sur entrée pour séparer chaque mot
						</div>
					</div>

					<div className={styles.tagsContainer} onClick={handleFocusOnInput}>
						{question.text?.criticalValues.map((tag, index) => (
							<div
								onClick={() =>
									updateQuestion(question, {
										text: {
											criticalEnabled: question.text?.criticalEnabled
												? true
												: false,
											criticalValues:
												question.text?.criticalValues.filter(
													(_, i) => i !== index
												) || [],
										},
									})
								}
								key={index}
								className={styles.tag}
							>
								{tag}
								<CloseIcon />
							</div>
						))}
						<input
							ref={inputRef}
							className={styles.tagsInput}
							type='text'
							value={input}
							onChange={handleInputChange}
							onKeyDown={handleKeyDown}
							maxLength={MAX_CHARACTERS}
							style={{minWidth: '20px'}}
						/>
						<span
							ref={widthRef}
							className={styles.hiddenInput}
							aria-hidden='true'
						>
							{input}
						</span>
					</div>
				</div>
			)}
		</div>
	);
}
