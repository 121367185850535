import Switch from '../../Switch/Switch';
import {Question as QuestionType} from '../../../sharedTypes';
import {ReactComponent as PhotoIcon} from '../../icons/photo.svg';
import {ReactComponent as VideoIcon} from '../../icons/video.svg';
import {ReactComponent as FileIcon} from '../../icons/file.svg';
import {ReactComponent as PortraitIcon} from '../../icons/portrait.svg';
import {ReactComponent as LandscapeIcon} from '../../icons/landscape.svg';
import {ReactComponent as SquareIcon} from '../../icons/square.svg';
import Counter from './Counter/Counter';
import styles from './Settings.module.scss';

export default function MediaSettings({
	question,
	updateQuestion,
}: {
	question: QuestionType;
	updateQuestion: (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => void;
}) {
	return (
		<div className={styles.mediaSettings}>
			<div className={styles.column}>
				<div className={styles.button}>TYPE DE MÉDIA</div>
				<div className={styles.row}>
					<button
						onClick={() => {
							updateQuestion(question, {
								media: {
									mediaType: 'photo',
									maxFiles: question.media?.maxFiles || 1,
									videoMaxDuration: question.media?.videoMaxDuration || 30,
									hashing: question.media?.hashing || false,
									orientationEnabled:
										question.media?.orientationEnabled || false,
									orientation: question.media?.orientation || 'portrait',
								},
							});
						}}
						className={`${styles.symbol} ${
							question.media?.mediaType === 'photo' ? styles.selectedSymbol : ''
						}`}
					>
						<div className={styles.flex}>
							<PhotoIcon />
							Photos
						</div>
						<span className={styles.bubble} />
					</button>
					<button
						onClick={() => {
							updateQuestion(question, {
								media: {
									mediaType: 'video',
									maxFiles: question.media?.maxFiles || 1,
									videoMaxDuration: question.media?.videoMaxDuration || 30,
									hashing: question.media?.hashing || false,
									orientationEnabled:
										question.media?.orientationEnabled || false,
									orientation: question.media?.orientation || 'portrait',
								},
							});
						}}
						className={`${styles.symbol} ${
							question.media?.mediaType === 'video' ? styles.selectedSymbol : ''
						}`}
					>
						<div className={styles.flex}>
							<VideoIcon />
							Vidéos
						</div>
						<span className={styles.bubble} />
					</button>
					<button
						onClick={() => {
							updateQuestion(question, {
								media: {
									mediaType: 'file',
									maxFiles: question.media?.maxFiles || 1,
									videoMaxDuration: question.media?.videoMaxDuration || 30,
									hashing: question.media?.hashing || false,
									orientationEnabled:
										question.media?.orientationEnabled || false,
									orientation: question.media?.orientation || 'portrait',
								},
							});
						}}
						className={`${styles.symbol} ${
							question.media?.mediaType === 'file' ? styles.selectedSymbol : ''
						}`}
					>
						<div className={styles.flex}>
							<FileIcon />
							Fichiers
						</div>
						<span className={styles.bubble} />
					</button>
				</div>
			</div>
			<div className={styles.column}>
				<div className={styles.spacedFlex}>
					<div className={styles.button}>NOMBRE MAXIMUM</div>
					<Counter
						min={1}
						max={99}
						onChange={(value) => {
							updateQuestion(question, {
								media: {
									mediaType: question.media?.mediaType || 'photo',
									maxFiles: value,
									videoMaxDuration: question.media?.videoMaxDuration || 30,
									hashing: question.media?.hashing || false,
									orientationEnabled:
										question.media?.orientationEnabled || false,
									orientation: question.media?.orientation || 'portrait',
								},
							});
						}}
						value={question.media?.maxFiles || 1}
					/>
				</div>
				<div className={styles.subtitle}>
					Choisissez le nombre maximum de{' '}
					{question.media?.mediaType === 'photo'
						? 'photos'
						: question.media?.mediaType === 'video'
						? 'vidéos'
						: 'fichiers'}{' '}
					qui peuvent être ajoutées pour répondre à cette question
				</div>
			</div>
			{question.media?.mediaType === 'photo' && (
				<div className={styles.column}>
					<div
						onClick={() => {
							updateQuestion(question, {
								media: {
									mediaType: question.media?.mediaType || 'photo',
									maxFiles: question.media?.maxFiles || 1,
									videoMaxDuration: question.media?.videoMaxDuration || 30,
									hashing: !question.media?.hashing || false,
									orientationEnabled:
										!question.media?.orientationEnabled || false,
									orientation: question.media?.orientation || 'portrait',
								},
							});
						}}
						className={styles.hashing}
					>
						<div className={styles.spacedFlex}>
							<div className={styles.button}>
								PRÉFÉRENCE D'ORIENTATION DES PHOTOS
							</div>
							<Switch isOn={question.media?.hashing} />
						</div>
					</div>
					<p>Impose l’orientation lors de la prise de photos</p>
					<div className={styles.row}>
						<button
							onClick={() => {
								updateQuestion(question, {
									media: {
										mediaType: question.media?.mediaType || 'photo',
										maxFiles: question.media?.maxFiles || 1,
										videoMaxDuration: question.media?.videoMaxDuration || 30,
										hashing: question.media?.hashing || false,
										orientationEnabled:
											question.media?.orientationEnabled || false,
										orientation: 'portrait',
									},
								});
							}}
							className={`${styles.symbol} ${
								question.media?.orientation === 'portrait'
									? styles.selectedSymbol
									: ''
							}`}
						>
							<div className={styles.flex}>
								<PortraitIcon />
								Portrait
							</div>
							<span className={styles.bubble} />
						</button>
						<button
							onClick={() => {
								updateQuestion(question, {
									media: {
										mediaType: question.media?.mediaType || 'photo',
										maxFiles: question.media?.maxFiles || 1,
										videoMaxDuration: question.media?.videoMaxDuration || 30,
										hashing: question.media?.hashing || false,
										orientationEnabled:
											question.media?.orientationEnabled || false,
										orientation: 'landscape',
									},
								});
							}}
							className={`${styles.symbol} ${
								question.media?.orientation === 'landscape'
									? styles.selectedSymbol
									: ''
							}`}
						>
							<div className={styles.flex}>
								<LandscapeIcon />
								Paysage
							</div>
							<span className={styles.bubble} />
						</button>
						<button
							onClick={() => {
								updateQuestion(question, {
									media: {
										mediaType: question.media?.mediaType || 'photo',
										maxFiles: question.media?.maxFiles || 1,
										videoMaxDuration: question.media?.videoMaxDuration || 30,
										hashing: question.media?.hashing || false,
										orientationEnabled:
											question.media?.orientationEnabled || false,
										orientation: 'square',
									},
								});
							}}
							className={`${styles.symbol} ${
								question.media?.orientation === 'square'
									? styles.selectedSymbol
									: ''
							}`}
						>
							<div className={styles.flex}>
								<SquareIcon />
								Carré
							</div>
							<span className={styles.bubble} />
						</button>
					</div>
				</div>
			)}
			{question.media?.mediaType === 'photo' && (
				<div className={styles.column}>
					<div
						onClick={() => {
							updateQuestion(question, {
								media: {
									mediaType: question.media?.mediaType || 'photo',
									maxFiles: question.media?.maxFiles || 1,
									videoMaxDuration: question.media?.videoMaxDuration || 30,
									hashing: !question.media?.hashing || false,
									orientationEnabled:
										!question.media?.orientationEnabled || false,
									orientation: question.media?.orientation || 'portrait',
								},
							});
						}}
						className={styles.hashing}
					>
						<div className={styles.spacedFlex}>
							<div className={styles.button}>HACHAGE DE L'IMAGE</div>
							<Switch isOn={question.media?.hashing} />
						</div>
						<p>Permet de détecter et d'empêcher les doublons</p>
					</div>
				</div>
			)}
			{question.media?.mediaType === 'video' && (
				<>
					<div className={styles.column}>
						<div className={styles.spacedFlex}>
							<div className={styles.button}>DURÉE MAXIMALE</div>
							<Counter
								min={1}
								max={99}
								onChange={(value) => {
									updateQuestion(question, {
										media: {
											mediaType: question.media?.mediaType || 'photo',
											maxFiles: question.media?.maxFiles || 1,
											videoMaxDuration: value,
											hashing: question.media?.hashing || false,
											orientationEnabled:
												question.media?.orientationEnabled || false,
											orientation: question.media?.orientation || 'portrait',
										},
									});
								}}
								value={question.media?.videoMaxDuration || 30}
							/>
						</div>
						<div className={styles.subtitle}>
							Indiquer la durée maximale en secondes
						</div>
					</div>
				</>
			)}
		</div>
	);
}
