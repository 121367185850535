import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useLayoutEffect,
} from 'react';
import styles from './TitleInput.module.scss';
import {ReactComponent as DeleteIcon} from '../icons/delete.svg';

const DEBOUNCE_DELAY = 300;

interface TitleInputProps {
	id?: string;
	mode?: 'simple' | 'context' | 'title' | 'comment' | 'grey' | 'blue';
	title: string;
	onChange: (value: string) => void;
	placeholder?: string;
	isNumber?: boolean;
}

const TitleInput: React.FC<TitleInputProps> = ({
	id,
	mode = 'simple',
	title,
	onChange,
	placeholder = 'Saisir le texte',
	isNumber = false,
}) => {
	const [localTitle, setLocalTitle] = useState(title);
	const isTyping = useRef(false);
	const timeoutId = useRef<NodeJS.Timeout | null>(null);
	const questionTitleRef = useRef<HTMLTextAreaElement>(null);
	let clickTimer: NodeJS.Timeout | null = null;
	const shortClickDuration = 200;
	const [shouldResize, setShouldResize] = useState(true);

	useEffect(() => {
		if (!isTyping.current) {
			setLocalTitle(title);
		}
	}, [title]);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		let newValue = e.target.value;

		if (isNumber) {
			newValue = newValue.replace(/[^\d]/g, '');
		}

		setLocalTitle(newValue);
		isTyping.current = true;

		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		timeoutId.current = setTimeout(() => {
			onChange(newValue);
			isTyping.current = false;
		}, DEBOUNCE_DELAY);
	};

	useEffect(() => {
		if (mode === 'blue') {
			questionTitleRef.current?.focus();
		}
	}, [mode]);

	useEffect(() => {
		if (mode === 'title' && localTitle === '') {
			questionTitleRef.current?.focus();
		}
	}, [mode, localTitle]);

	const handleResize = useCallback(() => {
		if (!shouldResize) return;

		const textarea = questionTitleRef.current;
		if (textarea) {
			const textarea = questionTitleRef.current;
			if (textarea) {
				textarea.style.height = '0px';
				textarea.style.height = `${textarea.scrollHeight + 1}px`;
			}
		}

		setShouldResize(false);
	}, [shouldResize]);

	useLayoutEffect(() => {
		handleResize();
		const textarea = questionTitleRef.current;
		if (textarea) {
			const resizeObserver = new ResizeObserver(() => {
				setShouldResize(true);
				handleResize();
			});
			resizeObserver.observe(textarea);
			return () => resizeObserver.disconnect();
		}
	}, [handleResize]);

	const handleClear = () => {
		setLocalTitle('');
		onChange('');
	};

	return (
		<div className={styles.titleInputContainer}>
			<textarea
				id={id}
				ref={questionTitleRef}
				className={
					mode === 'context'
						? localTitle === ''
							? styles.emptyContextInput
							: styles.contextInput
						: styles[mode]
				}
				value={localTitle}
				onChange={handleChange}
				placeholder={isNumber ? 'Saisir un nombre' : placeholder}
				onMouseDown={() => {
					clickTimer = setTimeout(() => {
						clickTimer = null;
					}, shortClickDuration);
				}}
				onMouseUp={(e) => {
					if (clickTimer) {
						clearTimeout(clickTimer);
						questionTitleRef.current?.setSelectionRange(
							0,
							questionTitleRef.current.value.length
						);
					}
				}}
			/>
			{mode === 'grey' && localTitle !== '' && (
				<button onClick={handleClear} className={styles.clearButton}>
					<DeleteIcon />
				</button>
			)}
		</div>
	);
};

export default TitleInput;
