import {useMemo} from 'react';
import {Chapter as ChapterType, Answer as Answers} from '../../../sharedTypes';
import {ReactComponent as FilledCheckIcon} from '../../icons/check_green.svg';
import styles from './QuestionSelector.module.scss';

export default function ProgressBar({
	chapter,
	chapters,
	answers,
	currentQuestionId,
	noPadding,
	width,
}: {
	chapter?: ChapterType;
	chapters?: ChapterType[];
	answers: {[key: string]: Answers};
	currentQuestionId?: string;
	noPadding?: boolean;
	width?: string;
}) {
	const answeredQuestions = useMemo(() => {
		if (chapters) {
			return chapters.reduce((total, chapter) => {
				return (
					total +
					(chapter.questions || []).filter(
						(question) => question.questionId && answers?.[question.questionId]
					).length
				);
			}, 0);
		} else if (chapter) {
			return (chapter.questions || []).filter(
				(question) => question.questionId && answers?.[question.questionId]
			).length;
		}
		return 0;
	}, [chapter, chapters, answers]);

	const totalQuestions = useMemo(() => {
		if (chapters) {
			return chapters.reduce((total, chapter) => {
				return total + (chapter.questions?.length || 0);
			}, 0);
		} else if (chapter) {
			return chapter.questions?.length || 0;
		}
		return 0;
	}, [chapter, chapters]);

	const currentQuestionIndex = useMemo(() => {
		if (chapter && currentQuestionId) {
			return (
				(chapter.questions || []).findIndex(
					(question) => question.questionId === currentQuestionId
				) + 1
			);
		}
		return 0;
	}, [chapter, currentQuestionId]);

	const progressPercentage = useMemo(() => {
		let progressPercentage = 0;

		if (currentQuestionId) {
			progressPercentage =
				currentQuestionIndex > 0
					? (currentQuestionIndex / totalQuestions) * 100
					: 0;
		} else {
			progressPercentage =
				totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;
		}
		return progressPercentage;
	}, [
		answeredQuestions,
		totalQuestions,
		currentQuestionId,
		currentQuestionIndex,
	]);

	const remainingQuestions = useMemo(() => {
		return totalQuestions - answeredQuestions;
	}, [totalQuestions, answeredQuestions]);

	return (
		<div
			className={`${styles.progressContainer} ${
				currentQuestionId ? styles.pxValues : ''
			} ${noPadding ? styles.noPadding : ''}`}
		>
			{progressPercentage === 100 && noPadding ? (
				<div className={`${styles.flex} ${chapter ? styles.smallIcons : ''}`}>
					<FilledCheckIcon />
					<div className={styles.tabBarItems}>
						{chapter ? 'Terminé' : 'Complète'}
					</div>
				</div>
			) : (
				<>
					{!currentQuestionId && (
						<div
							className={styles.tabBarItems}
						>{`${remainingQuestions} questions restantes`}</div>
					)}
					<div className={styles.progressBarBackground} style={{width: width}}>
						<div
							className={styles.progressBar}
							style={{width: `${progressPercentage}%`}}
						></div>
					</div>
					{currentQuestionId && currentQuestionIndex > 0 && (
						<div className={styles.tabBarItems}>
							{currentQuestionIndex} sur {totalQuestions} questions
						</div>
					)}
				</>
			)}
		</div>
	);
}
