import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
	message: string;
	isOpen: boolean;
	isSuccess: boolean;
	copyClipboard: boolean;
}

const initialState: ToastState = {
	message: '',
	isOpen: false,
	isSuccess: true,
	copyClipboard: false,
};

export const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		setToast: (
			state,
			action: PayloadAction<{
				message: string;
				success: boolean;
				copyClipboard?: boolean;
			}>
		) => {
			state.message = action.payload.message;
			state.isSuccess = action.payload.success;
			state.isOpen = true;
			if (action.payload.copyClipboard !== undefined) {
				state.copyClipboard = action.payload.copyClipboard;
			}
		},
		clearToast: (state) => {
			state.message = '';
			state.isOpen = false;
			state.copyClipboard = false;
		},
	},
});

export const { setToast, clearToast } = toastSlice.actions;

export default toastSlice.reducer;
