import React, {useMemo} from 'react';
import {Question as QuestionType} from '../../../sharedTypes';
import {ReactComponent as SmallCheckIcon} from '../../icons/check_small.svg';
import styles from './MultipleSelect.module.scss';

export default function MultipleSelect({
	question,
	value,
	onChange,
}: {
	question: QuestionType;
	value: string[];
	onChange: (value: string[]) => void;
}) {
	const handleSelectOption = (option: string) => {
		if (value.includes(option)) {
			onChange(value.filter((v) => v !== option));
		} else {
			onChange([...value, option]);
		}
	};

	const shuffledOptions = useMemo(() => {
		return [...(question.multipleSelect?.options || [])].sort(
			() => Math.random() - 0.5
		);
	}, [question.multipleSelect?.options]);

	return (
		<div className={styles.multipleSelect}>
			{shuffledOptions.map((option, index) => (
				<div
					className={`${styles.answer} ${
						value.includes(option) ? styles.selectedAnswer : ''
					}`}
					onClick={() => handleSelectOption(option)}
					key={index}
				>
					{option}
					<div
						className={`${styles.checkbox} ${
							value.includes(option) ? styles.selectedCheckbox : ''
						}`}
					>
						<SmallCheckIcon />
					</div>
				</div>
			))}
		</div>
	);
}
