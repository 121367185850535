import {
	useState,
	useEffect,
	cloneElement,
	isValidElement,
	useRef,
	useLayoutEffect,
	useCallback,
} from 'react';
import { useDrag } from '@use-gesture/react';
import { a, useSpring, config } from '@react-spring/web';
import styles from './BottomSheetWrapper.module.scss';

const BottomSheetWrapper: React.FC<{
	children: React.ReactElement<{
		close: (velocity?: number) => void;
	}>;
	trigger: React.ReactElement | number;
}> = ({ children, trigger }) => {
	const [height, setHeight] = useState<number>(document.body.clientHeight - 55);
	const [{ y }, api] = useSpring(() => ({ y: height }));
	const containerRef = useRef<HTMLDivElement>(null);
	const isInit = useRef<boolean>(false);

	useEffect(() => {
		const handleResize = () => {
			const newHeight =
				containerRef.current?.firstElementChild?.clientHeight ||
				document.body.clientHeight - 55;
			setHeight(Math.min(newHeight, document.body.clientHeight - 55));
		};

		const resizeObserver = new ResizeObserver((entries) => {
			if (isInit.current) {
				if (entries[0].target === containerRef.current?.firstElementChild) {
					const newHeight = entries[0].contentRect.height;
					if (newHeight !== 0) {
						setHeight(Math.min(newHeight, document.body.clientHeight - 55));
					}
				}
			} else {
				isInit.current = true;
			}
		});

		if (containerRef.current?.firstElementChild) {
			resizeObserver.observe(containerRef.current.firstElementChild);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			resizeObserver.disconnect();
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useLayoutEffect(() => {
		const checkHeight = () => {
			const height = containerRef.current?.firstElementChild?.clientHeight;
			if (height) {
				setHeight(Math.min(height, document.body.clientHeight - 55));
			} else {
				window.requestAnimationFrame(checkHeight);
			}
		};
		checkHeight();
	}, []);

	const customWobblyConfig = useCallback(() => {
		return {
			...config.wobbly,
			// mass: 5,
			// tension: 200,
			friction: 20,
			// clamp: false,
			// precision: 0.01,
			// velocity: 0,
			// damping: 14,
			// bounce: 0.15,
		};
	}, []);

	const open = useCallback(
		({ canceled }: { canceled: boolean }) => {
			api.start({
				y: 0,
				immediate: false,
				config: canceled ? customWobblyConfig : config.stiff,
			});
		},
		[customWobblyConfig, api]
	);

	const close = useCallback(
		(velocity = 0) => {
			api.start({
				y: height,
				immediate: false,
				config: { ...customWobblyConfig, duration: 300 },
			});
		},
		[height, customWobblyConfig, api]
	);

	const bind = useDrag(
		({
			event,
			last,
			velocity: [, vy],
			direction: [, dy],
			offset: [, oy],
			cancel,
			canceled,
		}) => {
			if (
				event.target instanceof Element &&
				event.target.closest('#scrollable-area')
			) {
				return;
			}
			if (oy < -70) cancel();

			if (last) {
				oy > height * 0.5 || (vy > 0.5 && dy > 0)
					? close(vy)
					: open({ canceled });
			} else api.start({ y: oy, immediate: true });
		},
		{
			from: () => [0, y.get()],
			filterTaps: true,
			bounds: { top: 0 },
			rubberband: true,
		}
	);

	const display = y.to((py) => (py < height ? 'block' : 'none'));

	const bgStyle = {
		opacity: y.to([0, height], [0.8, 0], 'clamp'),
		pointerEvents: y.to((py) => (py < height ? 'auto' : 'none')),
	};

	useEffect(() => {
		if (typeof trigger === 'number') {
			if (trigger !== 0) {
				open({ canceled: true });
			} else {
				close();
			}
		}
	}, [trigger, close, open]);

	let childWithClose;

	if (isValidElement(children)) {
		childWithClose = cloneElement(children, { close });
	} else {
		childWithClose = children;
	}
	return (
		<div className="flex" style={{ overflow: 'hidden' }}>
			<a.div className={styles.bg} style={bgStyle} onClick={() => close()} />
			{typeof trigger !== 'number' && (
				<div onClick={() => open({ canceled: true })}>{trigger}</div>
			)}
			<a.div
				ref={containerRef}
				className={styles.sheet}
				{...bind()}
				style={{ display, bottom: `calc(-100% + ${height - 100}px)`, y }}
			>
				{childWithClose}
			</a.div>
		</div>
	);
};

export default BottomSheetWrapper;
