import React from 'react';
import {Question as QuestionType} from '../../../sharedTypes';
import TitleInput from 'components/TitleInput/TitleInput';
import styles from './Settings.module.scss';

export default function Number({
	question,
	value,
	onChange,
}: {
	question: QuestionType;
	value: string;
	onChange: (value: string) => void;
}) {
	const handleChange = (value: string) => {
		onChange(parseFloat(value).toString());
	};
	return (
		<div className={styles.inputContainer}>
			<TitleInput
				isNumber={true}
				mode='simple'
				title={`${parseInt(value) || ''}`}
				onChange={(value) => handleChange(value)}
			/>
		</div>
	);
}
