import { useState, useMemo } from 'react';
import { getSocket } from '../../store/socket';
// import {
// 	useGetEditingChecklistQuery,
// 	// useGetEditingResponseQuery,
// } from '../../store/api';
import { ReactComponent as HistoryIcon } from '../icons/history.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/arrow_down.svg';
import { ReactComponent as SingleSelectIcon } from '../icons/single.svg';
import { ReactComponent as MultipleSelectIcon } from '../icons/multiple.svg';
import { ReactComponent as TextIcon } from '../icons/text.svg';
import { ReactComponent as NumberIcon } from '../icons/number.svg';
import { ReactComponent as DateIcon } from '../icons/date.svg';
import { ReactComponent as MediaIcon } from '../icons/media.svg';
import { ReactComponent as PreviousIcon } from '../icons/previous.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as NextIcon } from '../icons/next.svg';
import { ReactComponent as CommentIcon } from '../icons/comment.svg';
import { ReactComponent as CommentFilledIcon } from '../icons/comment_filled.svg';
import { ReactComponent as ViewsIcon } from '../icons/views.svg';
import { ReactComponent as ViewsFilledIcon } from '../icons/views_filled.svg';
import SingleSelectSettings from './Questions/SingleSelectSettings';
import MultipleSelectSettings from './Questions/MultipleSelectSettings';
// import DateSettings from './Questions/DateSettings';
// import NumberSettings from './Questions/NumberSettings';
// import TextSettings from './Questions/TextSettings';
// import MediaSettings from './Questions/MediaSettings';
import QuestionSelector from './QuestionSelector/QuestionSelector';
import BottomSheetWrapper from '../BottomSheetWrapper/BottomSheetWrapper';
import Date from './Questions/DateSelect';
import Media from './Questions/Media';
import MultipleSelect from './Questions/MultipleSelect';
import Number from './Questions/Number';
import SingleSelect from './Questions/SingleSelect';
import Text from './Questions/Text';
import TypeSelectionPopup from '../TypeSelectionPopup';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
	Answer as Answers,
} from '../../sharedTypes';
import styles from './Question.module.scss';
import TitleInput from '../TitleInput/TitleInput';
import { Upload, renderFile } from '../Upload';
import { getNextUnansweredQuestionId, isAnswered } from './checklistUtils';
import { useDeviceType } from 'useDeviceType';
import DeletePopup from './DeletePopup';

export default function Question({
	question,
	checklistId,
	responseId,
	navigation,
	setNavigation,
	mode,
	values,
	checklist,
	panelMode,
	setPanelMode,
	isLastQuestion = false,
}: {
	question: QuestionType;
	checklistId: string;
	responseId: string | undefined;
	navigation: string;
	setNavigation: (navigation: string) => void;
	mode: 'edit' | 'response';
	values?: { [key: string]: Answers };
	checklist: ChecklistType;
	panelMode?: 'comments' | 'views' | 'history' | null;
	setPanelMode?: (mode: 'comments' | 'views' | 'history' | null) => void;
	isLastQuestion?: boolean;
}) {
	const [animating, setAnimating] = useState(false);
	// const [imageScroll, setImageScroll] = useState<boolean>(false);
	// const scrollTimeoutRef = useRef<number | null>(null);
	const { isTypeOrLarger } = useDeviceType();

	// const handleScroll = () => {
	// 	setImageScroll(true);
	// 	if (scrollTimeoutRef.current) {
	// 		clearTimeout(scrollTimeoutRef.current);
	// 	}
	// 	scrollTimeoutRef.current = window.setTimeout(() => {
	// 		setImageScroll(false);
	// 	}, 150);
	// };

	// useEffect(() => {
	// 	return () => {
	// 		if (scrollTimeoutRef.current) {
	// 			clearTimeout(scrollTimeoutRef.current);
	// 		}
	// 	};
	// }, []);

	const handleUpdateQuestion = (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => {
		getSocket()?.emit('updateQuestion', {
			...question,
			...updates,
			required: question.required || false,
			checklistId,
		});
	};

	const handleValueChange = (
		newValue: string | string[],
		question: QuestionType
	) => {
		getSocket()?.emit('updateAnswer', {
			checklistId,
			responseId,
			questionId: question.questionId,
			answer: newValue,
		});
	};

	const handleNavigationChange = () => {
		setAnimating(true);
		setTimeout(() => {
			setNavigation('checklist');
			setAnimating(false);
		}, 500);
	};

	const chapter = useMemo(
		() =>
			checklist.chapters.find(
				(chapter: ChapterType) => chapter.chapterId === question.chapterId
			) as unknown as ChapterType,
		[checklist, question]
	);

	return (
		<div className={styles[mode]}>
			{mode === 'response' && responseId ? (
				<div
					className={`${styles.responseContainer} ${
						animating ? styles.slideDown : ''
					}`}
					style={{
						height: isTypeOrLarger('md') ? '100%' : 'calc(100% - 3.125rem)',
					}}
				>
					<div className={styles.content}>
						<div className={styles.headerNav}>
							<div className={styles.spacedFlex}>
								<ArrowDownIcon onClick={handleNavigationChange} />
								<MoreIcon />
							</div>
							<div className={styles.title}>
								{question.type === 'singleSelect' ? (
									<div className={styles.flex}>
										<SingleSelectIcon /> Sélection simple
									</div>
								) : question.type === 'multipleSelect' ? (
									<div className={styles.flex}>
										<MultipleSelectIcon /> Sélection multiple
									</div>
								) : question.type === 'text' ? (
									<div className={styles.flex}>
										<TextIcon /> Texte
									</div>
								) : question.type === 'number' ? (
									<div className={styles.flex}>
										<NumberIcon /> Nombre
									</div>
								) : question.type === 'date' ? (
									<div className={styles.flex}>
										<DateIcon /> Date
									</div>
								) : question.type === 'media' ? (
									<div className={styles.flex}>
										<MediaIcon /> Média
									</div>
								) : null}
								<h4>{question.title}</h4>
								<div className={styles.tabBarItems}>
									{chapter && chapter.title}
								</div>
							</div>
							<div className={styles.flex}>
								<button
									onClick={() => {
										const nextQuestion = getNextUnansweredQuestionId(
											checklist.chapters,
											question,
											true,
											values
										);
										if (nextQuestion) {
											const nextChapter = checklist.chapters.find(
												(c: ChapterType) =>
													(c.questions || []).some(
														(q: QuestionType) => q.questionId === nextQuestion
													)
											) as ChapterType | undefined;
											if (nextChapter) {
												setNavigation(
													`question_${nextChapter.chapterId}_${nextQuestion}`
												);
											}
										}
									}}
									className={styles.greyButton}
								>
									Répondre plus tard
								</button>
								<button className={styles.greyButton}>Historique</button>
							</div>
						</div>
						{question.type &&
							(question.type === 'singleSelect' ? (
								<SingleSelect
									question={question}
									value={(values?.[question.questionId] || '') as string}
									onChange={(newValue) => handleValueChange(newValue, question)}
									goToNextQuestion={() => {
										const nextQuestion = getNextUnansweredQuestionId(
											checklist.chapters,
											question,
											true,
											values
										);
										if (nextQuestion) {
											setNavigation(
												`question_${chapter.chapterId}_${nextQuestion}`
											);
										}
									}}
								/>
							) : question.type === 'multipleSelect' ? (
								<MultipleSelect
									question={question}
									value={(values?.[question.questionId] || []) as string[]}
									onChange={(newValue) => handleValueChange(newValue, question)}
								/>
							) : question.type === 'number' ? (
								<Number
									question={question}
									value={(values?.[question.questionId] || '') as string}
									onChange={(newValue) => handleValueChange(newValue, question)}
								/>
							) : question.type === 'text' ? (
								<Text
									question={question}
									value={(values?.[question.questionId] || '') as string}
									onChange={(newValue) => handleValueChange(newValue, question)}
								/>
							) : question.type === 'media' ? (
								<>
									{question.questionId ===
										'd8a9922c-876a-4a02-91ed-968922a03f35' && (
										<div
											style={{
												width: '100%',
												height: '100%',
												position: 'absolute',
												background: 'red',
											}}
										/>
									)}
									<Media
										question={question}
										value={(values?.[question.questionId] || []) as string[]}
										onChange={(question, newValue) => {
											handleValueChange(newValue, question);
										}}
									/>
								</>
							) : // ) : question.type === 'email' ? (
							// 	<Email
							// 		question={question}
							// 		value={(values?.[question.questionId] || '') as string}
							// 		onChange={(newValue) => handleValueChange(newValue, question)}
							// 	/>
							question.type === 'date' ? (
								<Date
									question={question}
									value={(values?.[question.questionId] || '') as string}
									onChange={(newValue) => handleValueChange(newValue, question)}
									goToNextQuestion={() => {
										const nextQuestion = getNextUnansweredQuestionId(
											checklist.chapters,
											question,
											true,
											values
										);
										if (nextQuestion) {
											const nextQuestionChapter = checklist.chapters.find(
												(c: ChapterType) =>
													(c.questions || []).some(
														(q: QuestionType) => q.questionId === nextQuestion
													)
											);
											if (nextQuestionChapter) {
												setNavigation(
													`question_${
														(nextQuestionChapter as ChapterType).chapterId
													}_${nextQuestion}`
												);
											}
										}
									}}
								/>
							) : null)}

						{(question?.context ||
							Object.values(question?.contextMedia || {}).length > 0 ||
							(question.useChapterContext && chapter?.context)) && (
							<div className={styles.context}>
								{question?.useChapterContext && chapter?.context && (
									<>
										<div className={styles.button}>Contexte</div>
										<p>{chapter.context}</p>
									</>
								)}
								{question?.context && !question?.useChapterContext && (
									<>
										<div className={styles.button}>Contexte</div>
										<p>{question.context}</p>
									</>
								)}

								{(question?.context ||
									Object.values(question?.contextMedia || {}).length > 0) && (
									<div>
										{Object.values(question?.contextMedia || {}).length > 0 && (
											<div
												className={styles.swipeableContainer}
												// onScroll={handleScroll}
											>
												{question.contextMedia && (
													<div className={styles.contextImageSection}>
														<div className={styles.contextImageContainer}>
															<img
																className={styles.contextImage}
																src={question.contextMedia}
																alt={`context`}
															/>
														</div>
													</div>
												)}
												{/* {question.contextMedia &&
													Array.from(question.contextMedia).map(
														(value, index) => (
															<div
																className={styles.contextImageSection}
																key={index}
															>
																<div className={styles.contextImageContainer}>
																	<div
																		style={{
																			visibility: imageScroll
																				? 'hidden'
																				: 'visible',
																		}}
																		className={styles.contextImageIndex}
																	>
																		{index + 1} /{' '}
																		{question.contextMedia?.length}
																	</div>
																	<div
																		style={{
																			visibility: imageScroll
																				? 'hidden'
																				: 'visible',
																		}}
																		className={styles.ellipsisContainer}
																	>
																	</div>
																	<img
																		className={styles.contextImage}
																		src={value}
																		alt={`context-${index}`}
																	/>
																</div>
															</div>
														)
													)} */}
											</div>
										)}
									</div>
								)}
							</div>
						)}

						{/* User responding this question :{' '}
					{editingResponse
						?.filter((user) => user.userId !== meData?._id)
						?.map(
							(user: {
								firstName: string;
								lastName: string;
								questionId: string;
							}) =>
								user.questionId === question.questionId
									? `${user.firstName} ${user.lastName}`
									: null
						)} */}
						<div className={styles.validate}>
							<button
								onClick={() => {
									if (isLastQuestion) {
										getSocket()?.emit('updateResponse', {
											responseId,
											isValidated: true,
										});
									} else {
										if (values && isAnswered(question.questionId, values)) {
											const nextQuestion = getNextUnansweredQuestionId(
												checklist.chapters,
												question,
												true,
												values
											);
											if (nextQuestion) {
												const nextChapter = checklist.chapters.find(
													(c: ChapterType) =>
														(c.questions || []).some(
															(q: QuestionType) => q.questionId === nextQuestion
														)
												) as ChapterType | undefined;
												if (nextChapter) {
													setNavigation(
														`question_${nextChapter.chapterId}_${nextQuestion}`
													);
												}
											}
										}
									}
								}}
								className={`${styles.primaryButton} ${
									values &&
									!isAnswered(question.questionId, values) &&
									!isLastQuestion
										? styles.disabled
										: ''
								}`}
							>
								{isLastQuestion ? 'Terminer' : 'Valider'}
							</button>
						</div>
					</div>
					{!isTypeOrLarger('md') && (
						<div className={styles.footerNav}>
							<PreviousIcon
								onClick={() => {
									const previousQuestion = getNextUnansweredQuestionId(
										checklist.chapters,
										question,
										false,
										values
									);
									if (previousQuestion) {
										const previousChapterId = checklist.chapters.find(
											(c: ChapterType) =>
												(c.questions || []).some(
													(q: QuestionType) => q.questionId === previousQuestion
												)
										) as ChapterType | undefined;
										if (previousChapterId) {
											setNavigation(
												`question_${previousChapterId.chapterId}_${previousQuestion}`
											);
										}
									}
								}}
							/>
							{values && (
								<BottomSheetWrapper trigger={<MenuIcon />}>
									<QuestionSelector
										chapter={chapter}
										chapters={checklist.chapters}
										navigation={navigation}
										setNavigation={setNavigation}
										values={values}
									/>
								</BottomSheetWrapper>
							)}
							<NextIcon
								onClick={() => {
									const nextQuestion = getNextUnansweredQuestionId(
										checklist.chapters,
										question,
										true,
										values
									);
									if (nextQuestion) {
										const nextChapterId = checklist.chapters.find(
											(c: ChapterType) =>
												(c.questions || []).some(
													(q: QuestionType) => q.questionId === nextQuestion
												)
										) as ChapterType | undefined;
										if (nextChapterId) {
											setNavigation(
												`question_${nextChapterId?.chapterId}_${nextQuestion}`
											);
										}
									}
								}}
							/>
						</div>
					)}
				</div>
			) : (
				<>
					<div className={styles.header}>
						<button
							onClick={() =>
								setPanelMode &&
								setPanelMode(panelMode === 'comments' ? null : 'comments')
							}
							className={styles.undoButton}
						>
							{panelMode === 'comments' ? (
								<CommentFilledIcon />
							) : (
								<CommentIcon />
							)}
						</button>
						<button
							onClick={() =>
								setPanelMode &&
								setPanelMode(panelMode === 'views' ? null : 'views')
							}
							className={styles.undoButton}
						>
							{panelMode === 'views' ? <ViewsFilledIcon /> : <ViewsIcon />}
						</button>
					</div>

					<div className={styles.context}>
						<div className={styles.mediaSection}>
							{question.contextMedia ? (
								<div className={styles.coverImage}>
									{renderFile(question.contextMedia.toString())}
									<div className={styles.coverImageButton}>
										<Upload
											uploadId={question.questionId}
											value={question.contextMedia || ''}
											onChange={(value) =>
												handleUpdateQuestion(question, {
													contextMedia: value,
												})
											}
											mode="photo"
											hide
											replace
											buttonLabel="Modifier l'image de contexte"
										/>
									</div>
								</div>
							) : (
								<div key={question.questionId}>
									<Upload
										uploadId={question.questionId}
										value={question.contextMedia || ''}
										onChange={(value) =>
											handleUpdateQuestion(question, {
												contextMedia: value,
											})
										}
										mode="photo"
										hide
										replace
										buttonLabel="Ajouter une image de contexte"
									/>
								</div>
							)}
						</div>
						<>
							<div className={styles.subtitle}>
								Question{' '}
								{(chapter.questions || []).findIndex(
									(q: QuestionType) => q.questionId === question.questionId
								) + 1}
							</div>
							<TitleInput
								mode={'title'}
								placeholder="Saisir le titre de la question"
								title={question.title}
								onChange={(value) =>
									handleUpdateQuestion(question, { title: value })
								}
							/>
						</>
						{
							<TitleInput
								mode={'context'}
								placeholder="Saisir le contexte"
								title={question.context || ''}
								onChange={(value) => {
									handleUpdateQuestion(question, {
										useChapterContext: false,
										context: value,
									});
								}}
							/>
						}
					</div>

					<div className={styles.historyButtons}>
						<TypeSelectionPopup
							selectedType={question.type || null}
							onSelection={(
								type:
									| 'singleSelect'
									| 'multipleSelect'
									| 'text'
									| 'number'
									| 'date'
									| 'media'
							) => {
								handleUpdateQuestion(question, { type: type, rules: [] });
							}}
						/>
						<button className={styles.greyButton}>
							<HistoryIcon /> Historique
						</button>
						<DeletePopup checklistId={checklistId} question={question} />
					</div>
					{/* <div className={styles.flex}>
						<button
							onClick={() => setQuestionNavigation('general')}
							className={`${styles.noBorderButton} ${
								questionNavigation === 'general' ? styles.selectedNoBorder : ''
							}`}
						>
							Général
						</button>
						<button
							onClick={() => setQuestionNavigation('context')}
							className={`${styles.noBorderButton} ${
								questionNavigation === 'context' ? styles.selectedNoBorder : ''
							}`}
						>
							Contexte
						</button>
						<button
							onClick={() => setQuestionNavigation('rules')}
							className={`${styles.noBorderButton} ${
								questionNavigation === 'rules' ? styles.selectedNoBorder : ''
							}`}
						>
							Conditions
						</button>
						</div> */}

					{question?.type &&
						(question.type === 'singleSelect' ? (
							<SingleSelectSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
							/>
						) : question.type === 'multipleSelect' ? (
							<MultipleSelectSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
							/>
						) : // ) : question.type === 'date' ? (
						// 	<DateSettings
						// 		question={question}
						// 		updateQuestion={handleUpdateQuestion}
						// 	/>
						// ) : question.type === 'number' ? (
						// 	<NumberSettings
						// 		question={question}
						// 		updateQuestion={handleUpdateQuestion}
						// 	/>
						// ) : question.type === 'text' ? (
						// 	<TextSettings
						// 		question={question}
						// 		updateQuestion={handleUpdateQuestion}
						// 	/>
						// ) : question.type === 'media' ? (
						// 	<MediaSettings
						// 		question={question}
						// 		updateQuestion={handleUpdateQuestion}
						// 	/>
						null)}
				</>
			)}
			<span className={styles.space} />
		</div>
	);
}
