import React, {useCallback, useEffect, useRef} from 'react';
import {Upload} from '../../Upload';
import {Question as QuestionType} from '../../../sharedTypes';
import styles from './Media.module.scss';

export default function Media({
	question,
	value,
	onChange,
}: {
	question: QuestionType;
	value: string[];
	onChange: (question: QuestionType, value: string[]) => void;
}) {
	const renderCount = useRef(0);

	useEffect(() => {
		renderCount.current += 1;
	});

	const handleChange = useCallback(
		(newValue: string[]) => {
			onChange(question, newValue);
		},
		[question, onChange]
	);

	return (
		<div className={styles.mediaContainer}>
			<Upload
				key={question.questionId}
				mode={question.media?.mediaType || 'photo'}
				maxFiles={question.media?.maxFiles || 1}
				value={value}
				onChange={handleChange}
				uploadId={question.questionId}
			/>
		</div>
	);
}
