import React from 'react';
import styles from './Switch.module.scss';

interface SwitchProps {
	isOn?: boolean;
}

const Switch: React.FC<SwitchProps> = ({isOn}) => {
	return (
		<div className={`${styles.switch} ${isOn ? styles.on : styles.off}`}>
			<div className={styles.toggle} />
		</div>
	);
};

export default Switch;
