import {useState, useEffect, useRef} from 'react';
import Carousel from './Carousel/Carousel';
import styles from './Calendar.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../store/rootReducer';
import {DateSelector} from './DateSelector/DateSelector';
import {
	differenceInDays,
	startOfWeek,
	endOfWeek,
	addDays,
	addMonths,
	addYears,
} from 'date-fns';
import {
	setCreatedAtStartDate,
	setCreatedAtEndDate,
	setStartDate,
	setEndDate,
	setYear,
	setMonths,
	setYears,
	setToComeFilter,
	setFilter,
	centerAt,
} from './calendarSlice';
import {ReactComponent as LeftIcon} from 'components/icons/chevron_left.svg';
import {ReactComponent as RightIcon} from 'components/icons/chevron_right.svg';
import {ReactComponent as MultipleSelectIcon} from 'components/icons/multiple.svg';
import {ReactComponent as SingleSelectIcon} from 'components/icons/single.svg';
import {ReactComponent as CheckIcon} from 'components/icons/check.svg';
import {ReactComponent as CalendarIcon} from 'components/icons/date.svg';
import Switch from '../Switch/Switch';

const ModeButton = ({
	mode,
	selectedMode,
	setSelectedMode,
	title,
}: {
	mode: number;
	selectedMode: number;
	setSelectedMode: (mode: number) => void;
	title: string;
}) => {
	return (
		<button
			className={`${styles.navItem} ${
				mode === selectedMode ? styles.selected : ''
			}`}
			onClick={() => setSelectedMode(mode)}
		>
			{title}
		</button>
	);
};

const getDateRange = (filter: string) => {
	const today = new Date();
	switch (filter) {
		case "Aujourd'hui":
			return [today, today];
		case 'Cette semaine':
			return [
				startOfWeek(today, {weekStartsOn: 1}),
				endOfWeek(today, {weekStartsOn: 1}),
			];
		case 'Les 7 derniers jours':
			return [addDays(today, -7), today];
		case 'Les 30 derniers jours':
			return [addDays(today, -30), today];
		case 'Les 90 derniers jours':
			return [addDays(today, -90), today];
		case 'Cette année':
			return [
				new Date(today.getFullYear(), 0, 1),
				new Date(today.getFullYear(), 11, 31),
			];
		case 'Semaine prochaine':
			const nextWeekStart = addDays(startOfWeek(today, {weekStartsOn: 1}), 7);
			const nextWeekEnd = endOfWeek(nextWeekStart, {weekStartsOn: 1});
			return [nextWeekStart, nextWeekEnd];
		case 'Les 30 prochains jours':
			return [today, addDays(today, 30)];
		case 'Les 90 prochains jours':
			return [today, addDays(today, 90)];
		case 'Les 6 prochains mois':
			return [today, addMonths(today, 6)];
		case "L'année prochaine":
			return [addYears(today, 1), addYears(today, 1)];
		default:
			return [null, null];
	}
};

export default function Calendar({
	activeDays,
	newDimensions = false,
	isCreateAt = false,
	isEndDate = false,
	mode = 'default',
	close = () => {},
	selectDay,
	simpleTitle,
	newWidth = false,
}: {
	activeDays?: string[];
	newDimensions?: boolean;
	isCreateAt?: boolean;
	isEndDate?: boolean;
	mode?:
		| 'default'
		| 'responsive'
		| 'simple'
		| 'simple responsive'
		| 'integrated'
		| 'week';
	close?: () => void;
	selectDay?: (startDate: number | null, endDate?: number | null) => void;
	simpleTitle?: string;
	newWidth?: boolean;
}) {
	const dispatch = useDispatch();
	const [selectedMode, setSelectedMode] = useState<number>(0);
	const [localStartDate, setLocalStartDate] = useState<number | null>(null);
	const [localEndDate, setLocalEndDate] = useState<number | null>(null);

	const months = useSelector((state: RootState) => state.calendar.months);
	const years = useSelector((state: RootState) => state.calendar.years);
	const year = useSelector((state: RootState) => state.calendar.year);
	const toComeFilter = useSelector(
		(state: RootState) => state.calendar.toComeFilter
	);
	const filter = useSelector((state: RootState) => state.calendar.filter);
	const createdAtStartDate = useSelector(
		(state: RootState) => state.calendar.createdAtStartDate
	);
	const createdAtEndDate = useSelector(
		(state: RootState) => state.calendar.createdAtEndDate
	);
	const endStartDate = useSelector(
		(state: RootState) => state.calendar.endStartDate
	);
	const endEndDate = useSelector(
		(state: RootState) => state.calendar.endEndDate
	);

	const calendarRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isCreateAt) {
			setLocalStartDate(createdAtStartDate ? createdAtStartDate : null);
			setLocalEndDate(createdAtEndDate ? createdAtEndDate : null);
		}
		if (isEndDate) {
			setLocalStartDate(endStartDate ? endStartDate : null);
			setLocalEndDate(endEndDate ? endEndDate : null);
		}
	}, [
		createdAtEndDate,
		createdAtStartDate,
		endEndDate,
		endStartDate,
		isCreateAt,
		isEndDate,
	]);

	useEffect(() => {
		if (localStartDate) {
			dispatch(centerAt(localStartDate));
		}
	}, [localStartDate, localEndDate, dispatch]);

	useEffect(() => {
		function handleClickOutside(
			event: // click event
			MouseEvent
		) {
			if (
				containerRef.current &&
				calendarRef.current &&
				!calendarRef.current.contains(event.target as Node)
			) {
				close();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [containerRef, calendarRef, close]);

	const pastFilters = [
		`Aujourd'hui`,
		`Cette semaine`,
		`Les 7 derniers jours`,
		`Les 30 derniers jours`,
		`Les 90 derniers jours`,
		`Cette année`,
	];

	const toComeFilters = [
		`Semaine prochaine`,
		`Les 30 prochains jours`,
		`Les 90 prochains jours`,
		`Les 6 prochains mois`,
		`L'année prochaine`,
	];

	const monthNames = [
		'Janvier',
		'Février',
		'Mars',
		'Avril',
		'Mai',
		'Juin',
		'Juillet',
		'Août',
		'Septembre',
		'Octobre',
		'Novembre',
		'Décembre',
	];

	const getMonthNumber = (monthName: string) => {
		return monthNames.indexOf(monthName);
	};

	const handleSave = () => {
		let startDate: Date | null = null;
		let endDate: Date | null = null;

		// Check if any months are selected
		const selectedMonths = months.filter((month) => month.enabled);
		if (selectedMonths.length > 0) {
			const firstSelectedMonth = selectedMonths.reduce((prev, current) => {
				return prev.year < current.year ||
					(prev.year === current.year &&
						getMonthNumber(prev.month) < getMonthNumber(current.month))
					? prev
					: current;
			});
			const lastSelectedMonth = selectedMonths.reduce((prev, current) => {
				return prev.year > current.year ||
					(prev.year === current.year &&
						getMonthNumber(prev.month) > getMonthNumber(current.month))
					? prev
					: current;
			});

			startDate = new Date(
				firstSelectedMonth.year,
				getMonthNumber(firstSelectedMonth.month),
				1
			);
			endDate = new Date(
				lastSelectedMonth.year,
				getMonthNumber(lastSelectedMonth.month) + 1,
				0
			); // Last day of the last month
		}

		// Check if any years are selected but no months are selected
		if (!startDate && !endDate) {
			const selectedYears = years.filter((year) => year.enabled);
			if (selectedYears.length > 0) {
				const firstSelectedYear = Math.min(...selectedYears.map((y) => y.year));
				const lastSelectedYear = Math.max(...selectedYears.map((y) => y.year));

				startDate = new Date(firstSelectedYear, 0, 1); // January 1st of the first selected year
				endDate = new Date(lastSelectedYear, 11, 31); // December 31st of the last selected year
			}
		}

		if (filter) {
			const [start, end] = getDateRange(filter);
			startDate = start;
			endDate = end;
		}

		if (localEndDate && localStartDate && localEndDate !== localStartDate) {
			if (isCreateAt) {
				dispatch(setCreatedAtStartDate(localStartDate));
				dispatch(setCreatedAtEndDate(localEndDate));
			} else {
				dispatch(setStartDate(localStartDate));
				dispatch(setEndDate(localEndDate));
			}
		} else if (startDate && endDate) {
			if (isCreateAt) {
				dispatch(setCreatedAtStartDate(startDate.getTime()));
				dispatch(setCreatedAtEndDate(endDate.getTime()));
			} else {
				dispatch(setStartDate(startDate.getTime()));
				dispatch(setEndDate(endDate.getTime()));
			}
		}

		if (mode.includes('simple')) {
			selectDay && selectDay(startDate?.getTime() || localStartDate);
		} else {
			selectDay &&
				selectDay(
					startDate?.getTime() || localStartDate,
					endDate?.getTime() || localEndDate
				);
		}
		close();
	};

	return (
		<div
			ref={containerRef}
			className={mode.includes('responsive') ? styles.container : ''}
		>
			<div
				ref={calendarRef}
				className={`${styles.calendar} ${
					newDimensions ? styles.newDimensions : ''
				} ${styles[mode === 'week' ? 'integratedWeek' : mode]}`}
			>
				{mode === 'default' && (
					<div className={styles.nav}>
						<ModeButton
							mode={0}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							title='Dates'
						/>
						<ModeButton
							mode={1}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							title='Mois'
						/>
						<ModeButton
							mode={2}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							title='Année'
						/>
						<ModeButton
							mode={3}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							title='Période'
						/>
					</div>
				)}

				{simpleTitle && (
					<div className={styles.simpleHeader}>
						<CalendarIcon />
						{simpleTitle}
					</div>
				)}

				{selectedMode === 0 ? (
					<div className={styles.section}>
						{mode !== 'integrated' && mode !== 'week' && (
							<div className={styles.periodSelector}>
								<div className={styles.periodSelectors}>
									{mode.includes('simple') ? (
										<>
											<DateSelector
												date={localStartDate}
												otherDate={localEndDate}
												setStartDate={(date: number | null | undefined) =>
													date && setLocalStartDate(date)
												}
												setEndDate={(date: number | null | undefined) =>
													date && setLocalEndDate(date)
												}
												isStart={true}
												isSimple={true}
											/>
										</>
									) : (
										<>
											<DateSelector
												date={localStartDate}
												otherDate={localEndDate}
												setStartDate={(date: number | null | undefined) =>
													date && setLocalStartDate(date)
												}
												setEndDate={(date: number | null | undefined) =>
													date && setLocalEndDate(date)
												}
												isStart={true}
											/>

											<DateSelector
												date={localEndDate}
												otherDate={localStartDate}
												setStartDate={(date: number | null | undefined) =>
													date && setLocalStartDate(date)
												}
												setEndDate={(date: number | null | undefined) =>
													date && setLocalEndDate(date)
												}
												isStart={false}
											/>
										</>
									)}
								</div>
							</div>
						)}

						<Carousel
							activeDays={activeDays}
							setStartDate={(date: number | null | undefined) =>
								date && setLocalStartDate(date)
							}
							setEndDate={(date: number | null | undefined) =>
								date && setLocalEndDate(date)
							}
							startDate={localStartDate}
							endDate={localEndDate}
							mode={mode === 'default' ? 'calendar' : mode}
							selectDay={selectDay ? selectDay : () => {}}
							newWidth={newWidth}
						/>
					</div>
				) : selectedMode === 1 ? (
					<div className={styles.section}>
						<div className={styles.header}>
							Sélectionner un ou plusieurs filtres
						</div>
						<div className={styles.yearSelector}>
							<div className={styles.yearTitle}>{year}</div>
							<div className={styles.orderButtons}>
								<button
									onClick={() => year > 2021 && dispatch(setYear(year - 1))}
								>
									<LeftIcon />
								</button>
								<button
									onClick={() => year < 2024 && dispatch(setYear(year + 1))}
								>
									<RightIcon />
								</button>
							</div>
						</div>
						<div className={styles.elements}>
							{months.map(
								(month: {month: string; year: number; enabled: boolean}) => {
									if (month.year === year) {
										return (
											<div
												key={`${month.month}_${year}`}
												onClick={() => {
													dispatch(
														setMonths({
															month: month.month,
															year: year,
															enabled: !month.enabled,
														})
													);
													setLocalStartDate(null);
													setLocalEndDate(null);
												}}
												className={`${styles.element} ${styles.switch}`}
											>
												<div className={styles.iconTitle}>
													<MultipleSelectIcon />
													{month.month}
												</div>
												<Switch isOn={month.enabled} />
											</div>
										);
									}
									return null;
								}
							)}
						</div>
					</div>
				) : selectedMode === 2 ? (
					<div className={styles.section}>
						<div className={styles.header}>
							Sélectionner un ou plusieurs filtres
						</div>
						<div className={styles.elements}>
							{years.map((yearObj) => {
								const enabled = yearObj.enabled;
								return (
									<div
										onClick={() => {
											dispatch(
												setYears({year: yearObj.year, enabled: !enabled})
											);
											setLocalStartDate(null);
											setLocalEndDate(null);
										}}
										className={`${styles.element} ${styles.switch}`}
										key={yearObj.year}
									>
										<div className={styles.iconTitle}>
											<MultipleSelectIcon />
											{yearObj.year}
										</div>
										<Switch isOn={enabled} />
									</div>
								);
							})}
						</div>
					</div>
				) : (
					selectedMode === 3 && (
						<div className={styles.section}>
							<div className={styles.toComeButtons}>
								<button
									onClick={() => dispatch(setToComeFilter(false))}
									className={`${styles.toComeButton} ${
										!toComeFilter ? styles.selectedFilter : ''
									}`}
								>
									<div className={styles.ellipse} />
									Passée
								</button>
								<button
									onClick={() => dispatch(setToComeFilter(true))}
									className={`${styles.toComeButton} ${
										toComeFilter ? styles.selectedFilter : ''
									}`}
								>
									<div className={styles.ellipse} />A venir
								</button>
							</div>
							<div className={styles.header}>Sélectionner un filtre</div>
							<div className={styles.elements}>
								{toComeFilter
									? toComeFilters.map((name) => {
											return (
												<div
													onClick={() => {
														setLocalStartDate(null);
														setLocalEndDate(null);
														dispatch(setFilter(name));
													}}
													className={`${styles.element} ${
														name === filter ? styles.selectedElement : ''
													}`}
													key={name}
												>
													<div
														className={`${styles.iconTitle} ${
															name === filter ? styles.selectedElement : ''
														}`}
													>
														<SingleSelectIcon />
														{name}
													</div>
													{name === filter && (
														<div className={styles.icon}>
															<CheckIcon />
														</div>
													)}
												</div>
											);
									  })
									: pastFilters.map((name) => {
											return (
												<div
													onClick={() => {
														setLocalStartDate(null);
														setLocalEndDate(null);
														dispatch(setFilter(name));
													}}
													className={`${styles.element} ${
														name === filter ? styles.selectedElement : ''
													}`}
													key={name}
												>
													<div className={styles.iconTitle}>
														<SingleSelectIcon />
														{name}
													</div>
													{name === filter && (
														<div className={styles.icon}>
															<CheckIcon />
														</div>
													)}
												</div>
											);
									  })}
							</div>
						</div>
					)
				)}

				{mode !== 'integrated' && mode !== 'week' && (
					<div
						className={`${styles.footer} ${
							selectedMode === 0 ? styles.isTransformed : ''
						}`}
					>
						<button
							onClick={
								!localEndDate && !localStartDate && selectedMode === 0
									? () => {}
									: handleSave
							}
							className={`${styles.validate} ${
								!localEndDate && !localStartDate && selectedMode === 0
									? styles.disabled
									: ''
							}`}
						>
							{!localEndDate && !localStartDate && selectedMode === 0 ? (
								'Sélectionner une date'
							) : (
								<>
									<span>
										{localStartDate && localEndDate
											? `(${
													differenceInDays(
														new Date(localEndDate),
														new Date(localStartDate)
													) + 1
											  } jours)`
											: ''}{' '}
									</span>
									Appliquer
								</>
							)}
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
