import React, {useState, useEffect} from 'react';
import {getSocket} from '../store/socket';
import {ReactComponent as CloseIcon} from '../components/icons/close.svg';
import {ReactComponent as SendIcon} from '../components/icons/send_text.svg';
import {Comment as CommentType, Reply as ReplyType} from '../sharedTypes';
import {useGetCommentsQuery} from '../store/api';
import Avatar from './Avatar/Avatar';
import styles from './Comments.module.scss';
import TitleInput from './TitleInput/TitleInput';
import {useMeQuery} from 'store/userApi';
import {formatTimeDifference} from '../utils';

type CommentsProps = {
	roomId: string;
	entityType: 'checklist' | 'chapter' | 'question';
	entityId: string;
	close: () => void;
	noBorder?: boolean;
};

const Comments: React.FC<CommentsProps> = ({
	roomId,
	entityType,
	entityId,
	close,
	noBorder,
}) => {
	const {
		data: initialComments,
		isLoading,
		error,
	} = useGetCommentsQuery({entityType, entityId});
	const {data: meData} = useMeQuery({});
	const [comments, setComments] = useState<CommentType[]>([]);
	const [newComment, setNewComment] = useState('');
	const [replyContent, setReplyContent] = useState<{[key: string]: string}>({});
	const [commentToAnswer, setCommentToAnswer] = useState<{
		commentId: string;
		text: string;
		name: string;
	} | null>(null);

	useEffect(() => {
		if (initialComments) {
			setComments(initialComments);
		}
	}, [initialComments]);

	useEffect(() => {
		getSocket()?.emit('joinCommentRoom', entityId);
		return () => {
			getSocket()?.emit('leaveCommentRoom', entityId);
		};
	}, [roomId, entityId]);

	const handleCreateComment = () => {
		if (newComment.trim()) {
			getSocket()?.emit('createComment', {
				roomId: entityId,
				checklistId: entityType === 'checklist' ? entityId : undefined,
				chapterId: entityType === 'chapter' ? entityId : undefined,
				questionId: entityType === 'question' ? entityId : undefined,
				content: newComment,
			});
			setNewComment('');
		}
	};

	const handleReply = (commentId: string) => {
		if (replyContent[commentId]?.trim()) {
			getSocket()?.emit('replyToComment', {
				commentId,
				roomId: entityId,
				content: replyContent[commentId],
			});
			setReplyContent((prev) => ({...prev, [commentId]: ''}));
		}
	};

	// const handleDeleteComment = (commentId: string) => {
	// 	getSocket()?.emit('deleteComment', { commentId, roomId });
	// };

	// const handleDeleteReply = (commentId: string, replyId: string) => {
	// 	getSocket()?.emit('deleteReply', { commentId, replyId, roomId });
	// };

	if (isLoading) return <div>Loading comments...</div>;
	if (error) return <div>Error loading comments</div>;

	return (
		<div className={`${styles.comments} ${noBorder ? styles.noBorder : ''}`}>
			<div className={styles.header}>
				<h4>Commentaires</h4>
				<CloseIcon className={styles.closeIcon} onClick={close} />
			</div>
			<ul>
				{comments.map((comment) => (
					<li key={comment.commentId}>
						<div className={styles.flex}>
							<Avatar name={comment.userName} />
							<div className={styles.column}>
								<div className={styles.button}>{comment.userName}</div>
								<div className={styles.tabBarItems}>
									il y a {formatTimeDifference(new Date(), comment.createdAt)}
								</div>
							</div>
						</div>
						<div className={styles.button}>{comment.content}</div>
						<button
							onClick={() =>
								setCommentToAnswer({
									commentId: comment.commentId,
									text: comment.content,
									name: comment.userName,
								})
							}
							className={`${styles.button} ${styles.replyButton}`}
						>
							Répondre
						</button>
						{/* <button onClick={() => handleDeleteComment(comment._id)}>
							Delete
						</button> */}
						<ul>
							{comment.replies.map((reply: ReplyType) => (
								<li className={styles.reply} key={reply.replyId}>
									<div className={styles.flex}>
										<Avatar name={reply.userName} />
										<div className={styles.column}>
											<div className={styles.button}>{reply.userName}</div>
											<div className={styles.tabBarItems}>
												il y a{' '}
												{formatTimeDifference(new Date(), reply.createdAt)}
											</div>
										</div>
									</div>
									<div className={styles.button}>{reply.content}</div>
									<button
										onClick={() => {
											setCommentToAnswer({
												commentId: comment.commentId,
												text: reply.content,
												name: reply.userName,
											});
										}}
										className={`${styles.button} ${styles.replyButton}`}
									>
										Répondre
									</button>
									{/* <button
										onClick={() => handleDeleteReply(comment._id, reply._id)}
									>
										Delete
									</button> */}
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
			<div className={styles.footer}>
				{commentToAnswer ? (
					<>
						<div className={styles.spacedFlex}>
							<div className={styles.column}>
								<div className={styles.flex}>
									<Avatar name={commentToAnswer.name} />
									<div className={styles.button}>{commentToAnswer.name}</div>
								</div>
								<div className={styles.button}>{commentToAnswer.text}</div>
							</div>
							<CloseIcon onClick={() => setCommentToAnswer(null)} />
						</div>
						<div className={styles.flex}>
							<Avatar name={`${meData.firstName} ${meData.lastName}`} />
							<TitleInput
								mode='comment'
								title={replyContent[commentToAnswer.commentId] || ''}
								onChange={(value) =>
									setReplyContent((prev) => ({
										...prev,
										[commentToAnswer.commentId]: value,
									}))
								}
								placeholder='Ajouter un commentaire'
							/>
						</div>
						{replyContent[commentToAnswer.commentId] && (
							<button
								className={styles.primaryButton}
								onClick={() => {
									setCommentToAnswer(null);
									handleReply(commentToAnswer.commentId);
								}}
							>
								Envoyer
								<SendIcon />
							</button>
						)}
					</>
				) : (
					<>
						<div className={styles.flex}>
							{meData && meData.firstName && meData.lastName && (
								<Avatar name={`${meData.firstName} ${meData.lastName}`} />
							)}
							<TitleInput
								mode='comment'
								title={newComment}
								onChange={setNewComment}
								placeholder='Ajouter un commentaire'
							/>
						</div>
						{newComment && (
							<button
								className={styles.primaryButton}
								onClick={handleCreateComment}
							>
								Envoyer
								<SendIcon />
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Comments;
