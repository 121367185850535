const prodApi = 'https://soop.cl/api';
const stagingApi = 'https://staging.soop.cl/api';
const localhostApi = 'http://localhost:3000/api';

const prodSocket = 'https://soop.cl';
const stagingSocket = 'https://staging.soop.cl';
const localhostSocket = 'ws://localhost:3000';

const getBaseUrl = () => {
	if (window.location.hostname === 'soop.cl') return prodApi;
	if (window.location.hostname === 'staging.soop.cl') return stagingApi;
	return localhostApi;
};

const getSocketUrl = () => {
	if (window.location.hostname === 'soop.cl') return prodSocket;
	if (window.location.hostname === 'staging.soop.cl') return stagingSocket;
	return localhostSocket;
};

const config = {
	baseUrl: getBaseUrl(),
	socketUrl: getSocketUrl(),
};

export default config;
