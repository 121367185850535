export const generateDays = (today: Date): number[] => {
	today.setHours(0, 0, 0, 0);

	const lastMonth = new Date(today);
	lastMonth.setMonth(today.getMonth() - 1);
	lastMonth.setDate(1);

	while (lastMonth.getDay() !== 1) {
		lastMonth.setDate(lastMonth.getDate() - 1);
	}

	const nextMonth = new Date(today);
	nextMonth.setMonth(today.getMonth() + 1);

	nextMonth.setDate(1);
	nextMonth.setMonth(nextMonth.getMonth() + 1);
	nextMonth.setDate(0);
	while (nextMonth.getDay() !== 0) {
		nextMonth.setDate(nextMonth.getDate() + 1);
	}

	const days: number[] = [];
	const current = new Date(lastMonth);
	while (current <= nextMonth) {
		days.push(current.getTime());
		current.setDate(current.getDate() + 1);
	}

	return days;
};

export const removeMonth = (days: number[], direction: boolean) => {
	const firstDay = direction
		? new Date(days[0])
		: new Date(days[days.length - 1]);
	const newDays = [...days];

	if (direction && firstDay.getDate() === (direction ? 1 : 0)) {
		const thisMonthLength = new Date(
			firstDay.getFullYear(),
			firstDay.getMonth() + (direction ? 1 : 0),
			direction ? 0 : 1
		).getDate();
		newDays.splice(
			direction ? 0 : days.length - thisMonthLength,
			thisMonthLength
		);
	} else {
		let firstDayOfNextMonth = new Date(
			firstDay.getFullYear(),
			firstDay.getMonth() + (direction ? 1 : -1),
			direction ? 1 : 0
		);
		if (
			!direction &&
			firstDay.getTime() ===
				new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0).getTime()
		) {
			firstDayOfNextMonth = new Date(
				firstDay.getFullYear(),
				firstDay.getMonth(),
				1
			);
		}

		while (
			(direction && newDays[0] !== firstDayOfNextMonth.getTime()) ||
			(!direction &&
				newDays[newDays.length - 1] !== firstDayOfNextMonth.getTime())
		) {
			if (direction) {
				newDays.shift();
			} else {
				newDays.pop();
			}
		}
		const lastMonthLength = new Date(
			new Date(newDays[0]).getFullYear(),
			new Date(newDays[0]).getMonth() + (direction ? 1 : 0),
			direction ? 0 : 1
		).getDate();
		newDays.splice(
			direction ? 0 : days.length - lastMonthLength,
			lastMonthLength
		);
	}
	if (
		(direction && new Date(newDays[0]).getDay() !== 1) ||
		(!direction && new Date(newDays[newDays.length - 1]).getDay() !== 0)
	) {
		while (
			(direction && new Date(newDays[0]).getDay() !== 1) ||
			(!direction && new Date(newDays[newDays.length - 1]).getDay() !== 0)
		) {
			const newDate = new Date(newDays[direction ? 0 : newDays.length - 1]);
			newDate.setDate(newDate.getDate() + (direction ? -1 : 1));
			if (direction) {
				newDays.unshift(newDate.getTime());
			} else {
				newDays.push(newDate.getTime());
			}
		}
	}
	return newDays;
};

const fillDays = (
	date: Date,
	condition: (day: number, currentDate: Date) => boolean,
	increment: number,
	maxDays?: number
): number[] => {
	const filledDays: number[] = [];
	let counter = 0;
	let newDate = new Date(date.getTime());
	while (
		condition(newDate.getDay(), newDate) &&
		!(maxDays !== undefined && counter >= maxDays)
	) {
		newDate = new Date(newDate.setDate(newDate.getDate() + increment));
		filledDays.push(newDate.getTime());
		counter++;
	}
	return filledDays;
};

export const extraMonth = (lastDayOfState: Date, direction: boolean) => {
	const extraDays = fillDays(
		new Date(lastDayOfState),
		() => true,
		direction ? 1 : -1,
		35
	);

	function removeAdditionalWeeks() {
		let lastWeekContainsFirstDayOfMonth = false;
		for (let i = 0; i < 7; i++) {
			const day = new Date(extraDays[extraDays.length - i - 1]);
			if (
				new Date(extraDays[extraDays.length - i - 1]).getTime() ===
				new Date(day.getFullYear(), day.getMonth(), 1).getTime()
			) {
				lastWeekContainsFirstDayOfMonth = true;
			}
		}
		if (!lastWeekContainsFirstDayOfMonth) {
			extraDays.splice(extraDays.length - 7, 7);
		}
	}
	removeAdditionalWeeks();
	removeAdditionalWeeks();

	return extraDays;
};

export const groupDaysByWeek = (days: number[]) => {
	const weeks: number[][] = [];
	for (let i = 0; i < days.length; i += 7) {
		weeks.push(days.slice(i, i + 7));
	}
	return weeks;
};

export const groupDaysByMonth = (weeks: number[][]): number[][][] => {
	const months: number[][][] = [];

	let currentMonth: number[][] = [];
	for (let weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
		currentMonth.push(weeks[weekIndex]);
		for (const day of weeks[weekIndex]) {
			const date = new Date(day);
			const isLastDayOfMonth =
				date.getTime() ===
				new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
			if (isLastDayOfMonth && weekIndex !== 0) {
				months.push(currentMonth);
				if (
					weeks[weekIndex][weeks[weekIndex].length - 1] ===
					new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime()
				) {
					currentMonth = [];
				} else {
					currentMonth = [weeks[weekIndex]];
				}
			}
		}
	}

	return months.map((monthWeeks) => {
		const additionalDaysAfter = fillDays(
			new Date(new Date(monthWeeks[monthWeeks.length - 1][6])),
			(day: number, currentDate: Date) => currentDate.getDay() !== 0,
			1
		);
		const totalDays = [...monthWeeks.flat(), ...additionalDaysAfter];
		const lastDay = new Date(totalDays[totalDays.length - 1]);

		if (monthWeeks.length < 6) {
			const extraDays = fillDays(new Date(lastDay), () => true, 1, 7);
			totalDays.push(...extraDays);
		}

		const finalWeeks = Array.from({length: 6}, (_, i) =>
			totalDays.slice(i * 7, i * 7 + 7)
		);

		return finalWeeks;
	});
};

const isValidDate = (dateString: string) => {
	const regex = /^\d{2}\/\d{2}\/\d{4}$/;
	if (!regex.test(dateString)) return false;

	const [day, month, year] = dateString.split('/').map(Number);
	const date = new Date(year, month - 1, day);

	return (
		date.getFullYear() === year &&
		date.getMonth() + 1 === month &&
		date.getDate() === day
	);
};

export function setAndFormatStartDate(
	setStartDate: (startDate: number | null | undefined) => void,
	setEndDate: (endDate: number | undefined | null) => void,
	endDate: number | null,
	str: string,
	isReset?: boolean
) {
	if (isValidDate(str)) {
		const [day, month, year] = str.split('/').map(Number);
		const date = new Date(year, month - 1, day);

		if (endDate && endDate < date.getTime()) {
			setStartDate(endDate);
			setEndDate(date.getTime());
		} else {
			setStartDate(date.getTime());
		}
		if (isReset) {
			// state.days = generateDays(date);
		}
	} else {
		if (isReset) {
			setStartDate(undefined);
		} else {
			setStartDate(null);
		}
	}
}

export function setAndFormatEndDate(
	setStartDate: (startDate: number | undefined | null) => void,
	setEndDate: (endDate: number | undefined | null) => void,
	startDate: number | undefined,
	str: string,
	isReset?: boolean
) {
	if (isValidDate(str)) {
		const [day, month, year] = str.split('/').map(Number);
		const date = new Date(year, month - 1, day);

		if (startDate && startDate > date.getTime()) {
			setEndDate(startDate);
			setStartDate(date.getTime());
		} else {
			setEndDate(date.getTime());
		}
		if (isReset) {
			// state.days = generateDays(date);
		}
	} else {
		if (isReset) {
			setEndDate(undefined);
		} else {
			setEndDate(null);
		}
	}
}
