import React from 'react';
import Switch from '../../Switch/Switch';
import {Question as QuestionType} from '../../../sharedTypes';
import {ReactComponent as EqualIcon} from '../../icons/equal.svg';
import {ReactComponent as GreaterThanIcon} from '../../icons/chevron_right.svg';
import {ReactComponent as LessThanIcon} from '../../icons/chevron_left.svg';
import styles from './Settings.module.scss';

export default function NumberSettings({
	question,
	updateQuestion,
}: {
	question: QuestionType;
	updateQuestion: (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => void;
}) {
	return (
		<div className={styles.numberSettings}>
			<div
				onClick={() => {
					updateQuestion(question, {
						number: {
							criticalEnabled: !question.number?.criticalEnabled,
							mode: question.number?.mode || '=',
							criticalValue: question.number?.criticalValue,
						},
					});
				}}
				className={styles.lateralPannelSection}
			>
				<div className={styles.spacedFlex}>
					<div className={styles.button}>VALEURS CRITIQUES</div>
					<Switch isOn={question.number?.criticalEnabled} />
				</div>
				<p>
					Permet d'indiquer les réponses problématiques. Choisir le type de
					nombre (en dessous d'un certain nombre, au dessus d'un certain nombre,
					ou un nombre précis) puis saisir le nombre dans le champ dédié.
					<br />
					<br />
					Si cette réponse est donnée, elle sera mise en avant lorsque la
					Checklist aura été complétée.
				</p>
			</div>
			{question.number?.criticalEnabled && (
				<div className={styles.lateralPannelSection}>
					<div className={styles.flex}>
						<button
							onClick={() =>
								updateQuestion(question, {
									number: {
										criticalEnabled: question.number?.criticalEnabled
											? true
											: false,
										mode: '=',
										criticalValue: question.number?.criticalValue,
									},
								})
							}
							className={`${styles.symbol} ${
								question.number?.mode === '=' ? styles.selectedSymbol : ''
							}`}
						>
							<EqualIcon />
							Nombre précis
							<span className={styles.bubble} />
						</button>
						<button
							onClick={() =>
								updateQuestion(question, {
									number: {
										criticalEnabled: question.number?.criticalEnabled
											? true
											: false,
										mode: '<',
										criticalValue: question.number?.criticalValue,
									},
								})
							}
							className={`${styles.symbol} ${
								question.number?.mode === '<' ? styles.selectedSymbol : ''
							}`}
						>
							<LessThanIcon />
							En dessous de
							<span className={styles.bubble} />
						</button>
						<button
							onClick={() =>
								updateQuestion(question, {
									number: {
										criticalEnabled: question.number?.criticalEnabled
											? true
											: false,
										mode: '>',
										criticalValue: question.number?.criticalValue,
									},
								})
							}
							className={`${styles.symbol} ${
								question.number?.mode === '>' ? styles.selectedSymbol : ''
							}`}
						>
							<GreaterThanIcon />
							Au dessus de
							<span className={styles.bubble} />
						</button>
					</div>
					<div className={styles.search}>
						<input
							className={styles.input}
							type='number'
							value={question.number?.criticalValue || ''}
							placeholder='Saisir un nombre'
							onChange={(e) =>
								updateQuestion(question, {
									number: {
										criticalEnabled: question.number?.criticalEnabled
											? true
											: false,
										mode: question.number?.mode || '=',
										criticalValue: parseInt(e.target.value),
									},
								})
							}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
