import {Chapter as ChapterType, Answer as Answers} from '../../../sharedTypes';
import {ReactComponent as PreviousIcon} from '../../icons/previous.svg';
import {ReactComponent as NextIcon} from '../../icons/next.svg';
import {ReactComponent as PlayFilledIcon} from '../../icons/play_circle_filled.svg';
import {ReactComponent as TranslateIcon} from '../../icons/translate.svg';
import {ReactComponent as ExclamationMarkIcon} from '../../icons/exclamation_mark.svg';
import {ReactComponent as PlayCircle} from '../../icons/play_circle_primary.svg';
import {ReactComponent as CheckIcon} from '../../icons/check_circle.svg';
import {ReactComponent as CloseIcon} from '../../icons/close.svg';
import {getFirstQuestionOfChapter} from '../checklistUtils';
import ProgressBar from './ProgressBar';
import styles from './QuestionSelector.module.scss';

export default function QuestionSelector({
	close,
	chapter,
	chapters,
	navigation,
	setNavigation,
	values,
}: {
	close?: () => void;
	chapter: ChapterType;
	chapters: ChapterType[];
	navigation: string;
	setNavigation: (navigation: string) => void;
	values: {[key: string]: Answers};
}) {
	const handleNextChapter = (direction: boolean) => {
		const firstQuestionOfNextChapter = getFirstQuestionOfChapter(
			chapters,
			chapters.findIndex((c) => c.chapterId === chapter.chapterId),
			direction
		);
		if (firstQuestionOfNextChapter) {
			setNavigation(
				`question_${chapter.chapterId}_${firstQuestionOfNextChapter}`
			);
		}
	};

	return (
		<div className={styles.questionSelector}>
			<div className={styles.header}>
				<div className={styles.closeButton}>
					<CloseIcon onClick={close} />
				</div>
				<h4>{chapter.title}</h4>
				<p>{chapter.context}</p>
				<ProgressBar chapter={chapter} answers={values} />
				<div className={styles.spacedFlex}>
					<div className={styles.flex}>
						<button className={styles.secondaryButton}>
							<TranslateIcon /> Traduire
						</button>
						<button className={styles.secondaryButton}>
							<ExclamationMarkIcon />
							Signaler
						</button>
					</div>
					<PlayCircle
						onClick={() => {
							if (Array.isArray(chapters) && chapters.length > 0) {
								const firstUnansweredQuestionId = chapters
									.flatMap((chapter: ChapterType) => chapter.questions || [])
									.find(
										(question) =>
											question.questionId && !values?.[question.questionId]
									)?.questionId;

								if (firstUnansweredQuestionId) {
									setNavigation(
										`question_${chapter.chapterId}_${firstUnansweredQuestionId}`
									);
								} else {
									const firstChapter = (chapters as ChapterType[])[0];
									if (
										firstChapter &&
										Array.isArray(firstChapter.questions) &&
										firstChapter.questions.length > 0
									) {
										const firstQuestionId =
											firstChapter.questions[0].questionId;
										if (firstQuestionId) {
											setNavigation(
												`question_${chapter.chapterId}_${firstQuestionId}`
											);
										}
									}
								}
							}
							close && close();
						}}
					/>
				</div>
			</div>
			<div className={styles.content}>
				<h5>Questions</h5>
				{(chapter.questions || []).map((question, index) => (
					<div
						key={index}
						onClick={() => {
							setNavigation(
								`question_${chapter.chapterId}_${question.questionId}`
							);
							close && close();
						}}
						className={`${styles.question} ${
							navigation ===
							`question_${chapter.chapterId}_${question.questionId}`
								? styles.selected
								: ''
						}`}
					>
						<div className={styles.questionText}>
							<div className={styles.button}>{question.title}</div>
							<div className={styles.tabBarItems}>
								{question.type === 'multipleSelect'
									? 'Choix multiples'
									: question.type === 'singleSelect'
									? 'Choix simple'
									: question.type === 'text'
									? 'Texte'
									: question.type === 'number'
									? 'Nombre'
									: question.type === 'date'
									? 'Date'
									: question.type === 'media'
									? 'Média'
									: question.type === 'email'
									? 'Email'
									: 'Non défini'}
							</div>
						</div>
						<div className={styles.questionActions}>
							{values[question.questionId] ? <CheckIcon /> : <PlayFilledIcon />}
						</div>
					</div>
				))}
			</div>
			<div className={styles.footerNav}>
				<PreviousIcon onClick={() => handleNextChapter(false)} />
				<div className={styles.tabBarItems}>
					Chapitre{' '}
					{chapters.findIndex((c) => c.chapterId === chapter.chapterId) + 1}
				</div>
				<NextIcon onClick={() => handleNextChapter(true)} />
			</div>
		</div>
	);
}
