import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {extraMonth, generateDays, removeMonth} from './calendarUtils';

const years = [2021, 2022, 2023, 2024, 2025];
const months = [
	'Janvier',
	'Février',
	'Mars',
	'Avril',
	'Mai',
	'Juin',
	'Juillet',
	'Août',
	'Septembre',
	'Octobre',
	'Novembre',
	'Décembre',
];

const monthObjects: {
	month: string;
	year: number;
	enabled: boolean;
}[] = [];
const yearObjects: {
	year: number;
	enabled: boolean;
}[] = years.map((year) => ({year, enabled: false}));

for (const year of years) {
	for (const month of months) {
		monthObjects.push({month, year, enabled: false});
	}
}

const date = new Date();
const firstOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
const lastOfMonth = new Date(
	date.getFullYear(),
	date.getMonth() + 1,
	0
).getTime();

interface CalendarState {
	days: number[];
	months: {month: string; year: number; enabled: boolean}[];
	year: number;
	years: {year: number; enabled: boolean}[];
	filter: string;
	toComeFilter: boolean;
	createdAtStartDate: number | null | undefined;
	createdAtEndDate: number | null | undefined;
	endStartDate: number | null | undefined;
	endEndDate: number | null | undefined;
	startDate: number | null | undefined;
	endDate: number | null | undefined;
	startModify: boolean;
	endModify: boolean;
	firstOfMonth: number;
	lastOfMonth: number;
}

const initialState: CalendarState = {
	days: generateDays(new Date()),
	months: monthObjects,
	year: 2024,
	years: yearObjects,
	filter: '',
	toComeFilter: false,
	createdAtStartDate: null,
	createdAtEndDate: null,
	endStartDate: null,
	endEndDate: null,
	startDate: null,
	endDate: null,
	startModify: false,
	endModify: false,
	firstOfMonth,
	lastOfMonth,
};

const calendarSlice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		incrementMonth: (state) => {
			const newDays = extraMonth(
				new Date(state.days[state.days.length - 1]),
				true
			);
			state.days.push(...newDays);
			state.days = removeMonth(JSON.parse(JSON.stringify(state.days)), true);
			const middleOfMonth = new Date(state.days[15]);
			state.firstOfMonth = new Date(
				middleOfMonth.getFullYear(),
				middleOfMonth.getMonth() + 1,
				1
			).getTime();
			state.lastOfMonth = new Date(
				middleOfMonth.getFullYear(),
				middleOfMonth.getMonth() + 2,
				0
			).getTime();
		},
		decrementMonth: (state) => {
			const newDays = extraMonth(new Date(state.days[0]), false);
			state.days = [...newDays.reverse(), ...state.days];
			state.days = removeMonth(JSON.parse(JSON.stringify(state.days)), false);
			const middleOfMonth = new Date(state.days[15]);
			state.firstOfMonth = new Date(
				middleOfMonth.getFullYear(),
				middleOfMonth.getMonth() + 1,
				1
			).getTime();
			state.lastOfMonth = new Date(
				middleOfMonth.getFullYear(),
				middleOfMonth.getMonth() + 2,
				0
			).getTime();
		},
		setCreatedAtStartDate: (state, action: PayloadAction<number>) => {
			state.createdAtStartDate = action.payload;
		},
		setCreatedAtEndDate: (state, action: PayloadAction<number>) => {
			state.createdAtEndDate = action.payload;
		},
		setEndStartDate: (state, action: PayloadAction<number>) => {
			state.endStartDate = action.payload;
		},
		setEndEndDate: (state, action: PayloadAction<number>) => {
			state.endEndDate = action.payload;
		},
		setStartDate: (state, action: PayloadAction<number>) => {
			state.startDate = action.payload;
		},
		setEndDate: (state, action: PayloadAction<number>) => {
			state.endDate = action.payload;
		},
		setStartModify: (state, action) => {
			state.startModify = action.payload;
		},
		setEndModify: (state, action) => {
			state.endModify = action.payload;
		},
		setMonths: (
			state,
			action: PayloadAction<{month: string; year: number; enabled: boolean}>
		) => {
			const {month, year, enabled} = action.payload;
			const monthIndex = state.months.findIndex(
				(m) => m.month === month && m.year === year
			);

			if (monthIndex === -1) return;

			if (enabled) {
				state.months[monthIndex].enabled = true;
				const selectedMonths = state.months
					.map((m, index) => (m.enabled ? index : -1))
					.filter((index) => index !== -1);

				if (selectedMonths.length > 1) {
					const start = Math.min(...selectedMonths);
					const end = Math.max(...selectedMonths);

					for (let i = start; i <= end; i++) {
						state.months[i].enabled = true;
					}
				}
			} else {
				state.months[monthIndex].enabled = false;
			}

			// reset filters
			state.filter = '';
			state.years = yearObjects;
		},
		setYears: (
			state,
			action: PayloadAction<{year: number; enabled: boolean}>
		) => {
			const {year, enabled} = action.payload;
			const yearIndex = state.years.findIndex((y) => y.year === year);

			if (yearIndex === -1) return;

			if (enabled) {
				state.years[yearIndex].enabled = true;

				const selectedYears = state.years
					.map((y, index) => (y.enabled ? index : -1))
					.filter((index) => index !== -1);

				if (selectedYears.length > 1) {
					const start = Math.min(...selectedYears);
					const end = Math.max(...selectedYears);

					for (let i = start; i <= end; i++) {
						state.years[i].enabled = true;
					}
				}
			} else {
				state.years[yearIndex].enabled = false;
			}

			// reset filters
			state.filter = '';
			state.months = monthObjects;
		},
		setYear: (state, action: PayloadAction<number>) => {
			state.year = action.payload;
		},
		setToComeFilter: (state, action: PayloadAction<boolean>) => {
			state.toComeFilter = action.payload;
		},
		setFilter: (state, action: PayloadAction<string>) => {
			if (state.filter !== action.payload) {
				state.filter = action.payload;
			} else {
				state.filter = '';
			}

			//reset months and years
			state.months = monthObjects;
			state.years = yearObjects;
		},
		centerAt: (state, action: PayloadAction<number>) => {
			const date = new Date(action.payload);
			const firstOfMonth = new Date(
				date.getFullYear(),
				date.getMonth(),
				1
			).getTime();
			const lastOfMonth = new Date(
				date.getFullYear(),
				date.getMonth() + 1,
				0
			).getTime();

			state.days = generateDays(new Date(action.payload));
			state.year = 2024;
			state.startDate = null;
			state.endDate = null;
			state.startModify = false;
			state.endModify = false;
			state.firstOfMonth = firstOfMonth;
			state.lastOfMonth = lastOfMonth;
		},
		resetDate: (state) => {
			state.createdAtStartDate = initialState.createdAtStartDate;
			state.createdAtEndDate = initialState.createdAtEndDate;
			state.endStartDate = initialState.endStartDate;
			state.endEndDate = initialState.endEndDate;
		},
	},
});

export const {
	incrementMonth,
	decrementMonth,
	setCreatedAtStartDate,
	setCreatedAtEndDate,
	setEndStartDate,
	setEndEndDate,
	setStartDate,
	setEndDate,
	setStartModify,
	setEndModify,
	setMonths,
	setYears,
	setYear,
	setToComeFilter,
	setFilter,
	centerAt,
	resetDate,
} = calendarSlice.actions;
export default calendarSlice.reducer;
