import { io, Socket } from 'socket.io-client';
import config from '../config';
import { store } from './store';
import { logout } from './userSlice';

let socket: Socket | null = null;
let isInitializing = false;

export const initializeSocket = (onConnect: () => void) => {
	if (socket?.connected) {
		onConnect();
		return socket;
	}

	if (isInitializing) {
		return null;
	}

	const token = localStorage.getItem('token');
	if (!token) {
		console.error('No token available, cannot initialize socket');
		return null;
	}

	isInitializing = true;
	socket = io(config.socketUrl, {
		auth: { token },
		transports: ['websocket'],
	});

	socket.on('connect_error', (error) => {
		console.error('Socket connection error:', error);
		handleConnectionError();
		isInitializing = false;
	});

	socket.once('connect', () => {
		onConnect();
		isInitializing = false;
	});

	return socket;
};

export const disconnectSocket = () => {
	if (socket) {
		socket.disconnect();
		socket = null;
	}
	isInitializing = false;
};

function handleConnectionError() {
	store.dispatch(logout());
}

export const getSocket = () => socket;

export const isSocketConnected = () => socket?.connected || false;
