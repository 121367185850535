import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
// Icons
import { ReactComponent as ErrorIcon } from 'components/icons/close.svg';
import { ReactComponent as SuccessIcon } from 'components/icons/check_circle.svg';
// Slices
import { clearToast } from './toastSlice';
// Styles
import styles from './Toast.module.scss';

const Toast = () => {
	const dispatch = useDispatch();
	const toast = useSelector((state: RootState) => state.toast);

	useEffect(() => {
		if (toast.isOpen) {
			const timer = setTimeout(() => {
				dispatch(clearToast());
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [toast, dispatch]);

	return toast.isOpen ? (
		<div
			className={`${styles.toast} ${
				toast.copyClipboard ? styles.copyClipboard : ''
			}`}
		>
			{!toast.copyClipboard ? (
				toast.isSuccess ? (
					<SuccessIcon />
				) : (
					<div className={styles.error}>
						<ErrorIcon />
					</div>
				)
			) : null}
			{toast.message}
		</div>
	) : null;
};

export default Toast;
