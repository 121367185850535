import { useRef, useEffect, useState } from 'react';
import { getSocket } from '../../store/socket';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
	Answer as Answers,
} from '../../sharedTypes';
import { ReactComponent as HistoryIcon } from '../icons/history.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { ReactComponent as FilledPlusIcon } from '../icons/filled_plus.svg';
import { ReactComponent as CommentIcon } from '../icons/comment.svg';
import { ReactComponent as CommentFilledIcon } from '../icons/comment_filled.svg';
import { ReactComponent as ViewsIcon } from '../icons/views.svg';
import { ReactComponent as ViewsFilledIcon } from '../icons/views_filled.svg';
import { Upload, renderFile } from '../Upload';
import Question from './Question';
import TitleInput from '../TitleInput/TitleInput';
import styles from './Checklist.module.scss';

export default function Chapter({
	chapter,
	checklistId,
	responseId,
	mode,
	navigation,
	setNavigation,
	values,
	checklist,
	panelMode,
	setPanelMode,
}: {
	chapter: ChapterType;
	checklistId: string;
	responseId: string | undefined;
	mode: 'edit' | 'response';
	navigation: string;
	setNavigation: (navigation: string) => void;
	values?: { [key: string]: Answers };
	checklist: ChecklistType;
	panelMode?: 'comments' | 'views' | 'history' | null;
	setPanelMode?: (mode: 'comments' | 'views' | 'history' | null) => void;
}) {
	const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [pendingCreation, setPendingCreation] = useState<
		'chapter' | 'question' | null
	>(null);
	const [updatesCount, setUpdatesCount] = useState(0);

	useEffect(() => {
		questionRefs.current = questionRefs.current.slice(
			0,
			chapter?.questions && Object.keys(chapter?.questions).length
		);
		while (
			questionRefs.current.length <
			((chapter?.questions && Object.keys(chapter?.questions).length) || 0)
		) {
			questionRefs.current.push(null);
		}
	}, [chapter]);

	useEffect(() => {
		const handleCreateResponse = (response: {
			questionId: string;
			chapterId: string;
		}) => {
			if (pendingCreation === 'chapter' && !response.questionId) {
				setNavigation(`chapter_${response.chapterId}`);
				setPendingCreation(null);
			} else if (pendingCreation === 'question' && response.questionId) {
				setNavigation(`question_${response.chapterId}_${response.questionId}`);
				setPendingCreation(null);
			}
		};

		getSocket()?.on('chapterCreated', handleCreateResponse);
		getSocket()?.on('questionCreated', handleCreateResponse);

		return () => {
			getSocket()?.off('chapterCreated', handleCreateResponse);
			getSocket()?.off('questionCreated', handleCreateResponse);
		};
	}, [pendingCreation, setNavigation]);

	const handleUpdateChapter = (
		chapter: ChapterType,
		updates: Partial<ChapterType>
	) => {
		getSocket()?.emit('updateChapter', {
			...chapter,
			...updates,
			checklistId: checklistId,
		});
	};

	const handleDeleteChapter = (chapterId: string) => {
		getSocket()?.emit('deleteChapter', {
			chapterId: chapterId,
			checklistId: checklistId,
		});
	};

	const handleCreateQuestion = () => {
		setPendingCreation('question');
		setUpdatesCount(updatesCount + 1);
		getSocket()?.emit('createQuestion', {
			checklistId,
			chapterId: chapter.chapterId,
			title: '',
			type: null,
			useChapterContext: true,
		});
	};

	return (
		<>
			{mode === 'edit' ? (
				navigation.includes('chapter') ? (
					<div className={styles.chapter}>
						<div className={styles.header}>
							<button
								onClick={() =>
									setPanelMode &&
									setPanelMode(panelMode === 'comments' ? null : 'comments')
								}
								className={styles.undoButton}
							>
								{panelMode === 'comments' ? (
									<CommentFilledIcon />
								) : (
									<CommentIcon />
								)}
							</button>
							<button
								onClick={() =>
									setPanelMode &&
									setPanelMode(panelMode === 'views' ? null : 'views')
								}
								className={styles.undoButton}
							>
								{panelMode === 'views' ? <ViewsFilledIcon /> : <ViewsIcon />}
							</button>
						</div>
						<div className={styles.titles}>
							{/* {chapter.contextMedia?.map((media, mIndex) => (
								<div key={mIndex}>{renderFile(media)}</div>
							))} */}
							{chapter.contextMedia ? (
								<div className={styles.coverImage}>
									{renderFile(chapter.contextMedia.toString())}
									<div className={styles.coverImageButton}>
										<Upload
											uploadId={chapter.chapterId}
											value={chapter.contextMedia || ''}
											onChange={(value) => {
												handleUpdateChapter(chapter, {
													contextMedia: value,
												});
											}}
											buttonLabel="Modifier l'image de contexte"
											mode="photo"
											hide
											replace
										/>
									</div>
								</div>
							) : (
								<Upload
									uploadId={chapter.chapterId}
									value={chapter.contextMedia || ''}
									onChange={(value) => {
										handleUpdateChapter(chapter, {
											contextMedia: value,
										});
									}}
									buttonLabel="Ajouter une image de contexte"
									mode="photo"
									hide
									replace
								/>
							)}
						</div>
						<div className={styles.subtitle}>
							<div className={styles.flex}>Chapitre {chapter.index + 1}</div>
							{/* <p>Saisir le titre du chapitre</p> */}
						</div>
						<div className={styles.titles}>
							<TitleInput
								mode={'title'}
								title={chapter.title}
								placeholder="Saisir le titre du chapitre"
								onChange={(value) =>
									handleUpdateChapter(chapter, { title: value })
								}
							/>
							{/* <div className={styles.separator} /> */}
							{/* <button
							className={styles.deleteButton}
							onClick={() => handleDeleteChapter(chapter.chapterId)}
						>
							Supprimer le chapitre
						</button> */}
							{/* <p className={styles.contextSubtitle}>
							Écrivez le contexte que vous souhaitez donner à ce chapitre
						</p> */}
							<TitleInput
								mode={'context'}
								placeholder="Contexte supplémentaire pour ce chapitre"
								title={chapter.context || ''}
								onChange={(value) =>
									handleUpdateChapter(chapter, { context: value })
								}
							/>
						</div>
						<div className={styles.buttonsFlex}>
							<button
								onClick={handleCreateQuestion}
								className={styles.createButton}
							>
								<FilledPlusIcon /> Créer une question
							</button>
							<button className={styles.greyButton}>
								<HistoryIcon /> Historique
							</button>
							<button
								onClick={() => setOpenDeletePopup(true)}
								className={styles.greyButton}
							>
								<MoreIcon />
							</button>
							{openDeletePopup && (
								<button
									className={styles.noBorderButton}
									onClick={() => handleDeleteChapter(chapter.chapterId)}
								>
									Supprimer le chapitre
								</button>
							)}
						</div>
						{/* <div className={styles.separator} /> */}
					</div>
				) : (
					chapter.questions?.map(
						(question: QuestionType) =>
							navigation.includes(question.questionId) && (
								<Question
									key={question.questionId}
									question={question}
									checklistId={checklistId}
									responseId={responseId}
									navigation={navigation}
									setNavigation={setNavigation}
									mode={mode}
									values={values}
									checklist={checklist}
									panelMode={panelMode}
									setPanelMode={setPanelMode}
								/>
							)
					)
				)
			) : (
				<>
					{chapter.context}
					{chapter.contextMedia &&
						Array.from(chapter.contextMedia).map((media, mIndex) => (
							<div key={mIndex}>{renderFile(media)}</div>
						))}
				</>
			)}
		</>
	);
}
