import { useState, useEffect, useCallback } from 'react';

const breakpoints = {
	xs: 320,
	sm: 480,
	md: 768,
	lg: 1024,
	xl: 1200,
	hd: 1366,
	fhd: 1536,
	uhd: 1920,
	quhd: 2560,
};

const deviceOrder = ['xs', 'sm', 'md', 'lg', 'xl', 'hd', 'fhd', 'uhd', 'quhd'];

function getDeviceType(width: number) {
	if (width < breakpoints.sm) return 'xs';
	if (width < breakpoints.md) return 'sm';
	if (width < breakpoints.lg) return 'md';
	if (width < breakpoints.xl) return 'lg';
	if (width < breakpoints.hd) return 'xl';
	if (width < breakpoints.fhd) return 'hd';
	if (width < breakpoints.uhd) return 'fhd';
	if (width < breakpoints.quhd) return 'uhd';
	return 'quhd';
}

export function useDeviceType() {
	const [deviceType, setDeviceType] = useState(
		getDeviceType(window.innerWidth)
	);
	const [sizeMultiplicator, setSizeMultiplicator] = useState(1);

	const isTypeOrLarger = useCallback(
		(
			checkType:
				| 'xs'
				| 'sm'
				| 'md'
				| 'lg'
				| 'xl'
				| 'hd'
				| 'fhd'
				| 'uhd'
				| 'quhd'
		) => {
			const deviceIndex = deviceOrder.indexOf(deviceType);
			const checkTypeIndex = deviceOrder.indexOf(checkType);
			return deviceIndex >= checkTypeIndex;
		},
		[deviceType]
	);

	const isTypeOrSmaller = useCallback(
		(
			checkType:
				| 'xs'
				| 'sm'
				| 'md'
				| 'lg'
				| 'xl'
				| 'hd'
				| 'fhd'
				| 'uhd'
				| 'quhd'
		) => {
			const deviceIndex = deviceOrder.indexOf(deviceType);
			const checkTypeIndex = deviceOrder.indexOf(checkType);
			return deviceIndex <= checkTypeIndex;
		},
		[deviceType]
	);

	const stringToResponsive = useCallback(
		(value: string) => {
			if (value.endsWith('px')) {
				const responsiveHeight = parseInt(value.replace('px', ''));
				return `${responsiveHeight * sizeMultiplicator}px`;
			} else if (value.startsWith('calc')) {
				const responsiveHeight = parseInt(
					value.replace('calc(100% - ', '').replace(')', '')
				);
				return `calc(100% - ${responsiveHeight * sizeMultiplicator}px)`;
			} else {
				return value;
			}
		},
		[sizeMultiplicator]
	);

	useEffect(() => {
		function handleResize() {
			setDeviceType(getDeviceType(window.innerWidth));

			const isMdOrLarger = isTypeOrLarger('md');
			const isLgOrLarger = isTypeOrLarger('lg');
			const isXlOrLarger = isTypeOrLarger('xl');
			const isHdOrLarger = isTypeOrLarger('hd');
			const isFhdOrLarger = isTypeOrLarger('fhd');
			const isUhdOrLarger = isTypeOrLarger('uhd');
			const isQuhdOrLarger = isTypeOrLarger('quhd');

			setSizeMultiplicator(
				isQuhdOrLarger
					? 1
					: isUhdOrLarger
					? 0.95
					: isFhdOrLarger
					? 0.9
					: isHdOrLarger
					? 0.85
					: isXlOrLarger
					? 0.8
					: isLgOrLarger
					? 0.75
					: isMdOrLarger
					? 0.7
					: 1
			);
		}

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isTypeOrLarger]);

	return {
		deviceType,
		isTypeOrLarger,
		isTypeOrSmaller,
		sizeMultiplicator,
		stringToResponsive,
	};
}
