import {useMemo, useEffect, useRef, useCallback} from 'react';
import {ReactComponent as CommentIcon} from '../icons/comment.svg';
import {ReactComponent as CommentIconFilled} from '../icons/comment_filled.svg';
import {ReactComponent as HelpIcon} from '../icons/exclamation_mark.svg';
import {ReactComponent as ArrowUpIcon} from '../icons/arrow_up.svg';
import {ReactComponent as ArrowDownIcon} from '../icons/arrow_down.svg';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
	Answer as Answers,
} from '../../sharedTypes';
import Checklist from '../Checklist/Checklist';
import {getNextQuestionId} from '../Checklist/checklistUtils';
import {useNavigation} from '../../NavigationContext';
import styles from './ScrollableMobileResponse.module.scss';

export default function ScrollableMobileResponse({
	id,
	data,
	values,
	responseId,
	isAutoNavigationDisabled,
	setIsAutoNavigationDisabled,
	commentsOn,
	setCommentsOn,
}: {
	id: string;
	data: ChecklistType;
	values: {[key: string]: Answers};
	responseId: string;
	isAutoNavigationDisabled: boolean;
	setIsAutoNavigationDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	commentsOn: boolean;
	setCommentsOn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const {navigation, setNavigation} = useNavigation();
	const containerRef = useRef<HTMLDivElement>(null);
	const questionRefs = useRef<{[key: string]: HTMLDivElement | null}>({});
	const chapter = useMemo(
		() =>
			navigation.includes('question')
				? (data.chapters?.find(
						(chapter: ChapterType) =>
							chapter.chapterId === navigation.split('_')[1]
				  ) as ChapterType | undefined)
				: null,

		[navigation, data]
	);

	const question = useMemo(() => {
		if (navigation.includes('question') && chapter) {
			return (chapter.questions || []).find(
				(question: QuestionType) =>
					question.questionId === navigation.split('_')[2]
			);
		}
		return null;
	}, [navigation, chapter]);

	const updateNavigationOnScroll = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			if (!isAutoNavigationDisabled) {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const questionId = entry.target.id;
						const chapterId = chapter?.chapterId;
						setNavigation(`question_${chapterId}_${questionId}`);
					}
				});
			}
		},
		[setNavigation, isAutoNavigationDisabled, chapter]
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && question) {
				event.preventDefault();
				const direction = event.key === 'ArrowUp' ? false : true;
				const nextQuestionId = getNextQuestionId(
					data.chapters,
					question,
					direction
				);
				if (nextQuestionId) {
					const nextChapter = data.chapters.find((c: ChapterType) =>
						(c.questions || []).some(
							(q: QuestionType) => q.questionId === nextQuestionId
						)
					) as ChapterType | undefined;
					if (nextChapter) {
						setNavigation(
							`question_${nextChapter.chapterId}_${nextQuestionId}`
						);
						setIsAutoNavigationDisabled(true);
					}
				}
			}
		},
		[data.chapters, question, setNavigation, setIsAutoNavigationDisabled]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	useEffect(() => {
		let scrollTimeout: NodeJS.Timeout;

		const observer = new IntersectionObserver(updateNavigationOnScroll, {
			root: containerRef.current,
			threshold: 0.5,
		});

		const observeQuestions = () => {
			observer.disconnect();
			Object.values(questionRefs.current).forEach((ref) => {
				if (ref) observer.observe(ref);
			});
		};

		const handleUserScroll = () => {
			if (containerRef.current) {
				clearTimeout(scrollTimeout);
				setIsAutoNavigationDisabled(false);
				setTimeout(() => {
					observeQuestions();
					setIsAutoNavigationDisabled(true);
				}, 1000);
			}
		};

		observeQuestions();

		const container = containerRef.current;
		if (container) {
			container.addEventListener('wheel', handleUserScroll);
			container.addEventListener('touchmove', handleUserScroll);
		}

		return () => {
			observer.disconnect();
			if (container) {
				container.removeEventListener('wheel', handleUserScroll);
				container.removeEventListener('touchmove', handleUserScroll);
			}
			clearTimeout(scrollTimeout);
		};
	}, [updateNavigationOnScroll, chapter, setIsAutoNavigationDisabled]);

	useEffect(() => {
		if (navigation.includes('question')) {
			const questionId = navigation.split('_')[2];
			const questionElement = questionRefs.current[questionId];
			if (questionElement && containerRef.current) {
				questionElement.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [navigation]);

	return (
		<div className={styles.gradientContainer}>
			<div className={styles.scrollContainer} ref={containerRef}>
				{chapter?.questions &&
					chapter.questions.map((question: QuestionType) => (
						<div
							key={question.questionId}
							id={question.questionId}
							ref={(el) => (questionRefs.current[question.questionId] = el)}
							className={styles.checklist}
						>
							<Checklist
								key={question.questionId}
								id={id}
								data={data}
								values={values}
								responseId={responseId}
								mode='response'
								navigation={`question_${chapter.chapterId}_${question.questionId}`}
								setNavigation={setNavigation}
							/>
						</div>
					))}
			</div>

			{question && (
				<div className={styles.navButtons}>
					<div className={styles.group}>
						<button onClick={() => setCommentsOn(!commentsOn)}>
							{commentsOn ? <CommentIconFilled /> : <CommentIcon />}
						</button>
						<button>
							<HelpIcon />
						</button>
					</div>
					<div className={styles.group}>
						<button
							onClick={() => {
								const previousQuestion = getNextQuestionId(
									data.chapters,
									question,
									false
									// values
								);
								if (previousQuestion) {
									const previousChapter = data.chapters.find((c: ChapterType) =>
										(c.questions || []).some(
											(q: QuestionType) => q.questionId === previousQuestion
										)
									) as ChapterType | undefined;
									if (previousChapter) {
										setNavigation(
											`question_${previousChapter.chapterId}_${previousQuestion}`
										);
										setIsAutoNavigationDisabled(true);
									}
								}
							}}
						>
							<ArrowUpIcon />
						</button>
						<button
							onClick={() => {
								const nextQuestion = getNextQuestionId(
									data.chapters,
									question,
									true
								);
								if (nextQuestion) {
									const nextChapter = data.chapters.find((c: ChapterType) =>
										(c.questions || []).some(
											(q: QuestionType) => q.questionId === nextQuestion
										)
									) as ChapterType | undefined;
									if (nextChapter) {
										setNavigation(
											`question_${nextChapter.chapterId}_${nextQuestion}`
										);
										setIsAutoNavigationDisabled(true);
									}
								}
							}}
						>
							<ArrowDownIcon />
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
