import {
	Checklist,
	Response,
	Answer as Answers,
	Comment,
	Folder,
	HistoryEvent,
} from '../sharedTypes';
import {createApiWithBaseQuery} from './apiBase';

type User = {
	checklistId: string;
	questionId: string;
	userId: string;
	firstName: string;
	lastName: string;
};

interface UploadResponse {
	message: string;
	files: {filename: string; url: string}[];
}

export const api = createApiWithBaseQuery('api').injectEndpoints({
	endpoints: (builder) => ({
		getChecklists: builder.query<
			{
				state: Checklist;
				checklistId: string;
				title: string;
				coverImage: string;
			}[],
			void
		>({
			query: () => ({
				url: '/checklists/',
				method: 'GET',
			}),
		}),
		getChecklist: builder.query<
			{
				state: Checklist;
				checklistId: string;
				title: string;
				folderId: string;
				lastModified: {
					timestamp: string;
					user: {
						firstName: string;
						lastName: string;
					};
				};
			},
			string
		>({
			query: (id) => ({
				url: `/checklists/${id}`,
				method: 'GET',
			}),
		}),
		getResponse: builder.query<Response, string>({
			query: (id) => ({
				url: `/responses/link/${id}`,
				method: 'GET',
			}),
		}),
		getResponses: builder.query<Response[], string>({
			query: (id) => ({
				url: `/responses/${id}`,
				method: 'GET',
			}),
		}),
		getAnswer: builder.query<{[key: string]: Answers}, string>({
			query: (id) => ({
				url: `/responses/answer/${id}`,
				method: 'GET',
			}),
		}),
		getAnswers: builder.query<
			(Response & {
				state: {[key: string]: Answers};
			})[],
			string
		>({
			query: (checklistId) => ({
				url: `/responses/answers/${checklistId}`,
				method: 'GET',
			}),
		}),
		uploadFiles: builder.mutation<UploadResponse, FormData>({
			query: (formData) => ({
				url: 'files',
				method: 'POST',
				body: formData,
			}),
		}),
		deleteFile: builder.mutation<{message: string}, {url: string}>({
			query: ({url}) => ({
				url: 'files',
				method: 'DELETE',
				body: {url},
			}),
		}),
		getEditingChecklist: builder.query<User[], string>({
			query: (id) => ({
				url: `/editing/checklist/${id}`,
				method: 'GET',
			}),
		}),
		getEditingResponse: builder.query<User[], string>({
			query: (id) => ({
				url: `/editing/response/${id}`,
				method: 'GET',
			}),
		}),
		getComments: builder.query<
			Comment[],
			{entityType: string; entityId: string}
		>({
			query: ({entityType, entityId}) => ({
				url: `/comment/${entityType}/${entityId}`,
				method: 'GET',
			}),
		}),
		getMe: builder.query<User, void>({
			query: () => ({
				url: '/user/me',
				method: 'GET',
			}),
		}),
		getFolders: builder.query<Folder[], void>({
			query: () => '/checklists/folders',
		}),
		getFolder: builder.query<Checklist[], {id: string; q?: string}>({
			query: ({id, q}) => ({
				url: `/checklists/folders/${id}`,
				params: {q},
			}),
		}),
		getHistoryFirstPage: builder.query<
			{
				events: HistoryEvent[];
				totalEvents: number;
				currentPage: number;
				totalPages: number;
			},
			{checklistId: string; page?: number; limit?: number}
		>({
			query: ({checklistId, limit = 20}) =>
				`/history/${checklistId}?page=1&limit=${limit}`,
		}),
		getHistoryNextPages: builder.query<
			{
				events: HistoryEvent[];
				totalEvents: number;
				currentPage: number;
				totalPages: number;
			},
			{checklistId: string; page?: number; limit?: number}
		>({
			query: ({checklistId, page = 1, limit = 20}) =>
				`/history/${checklistId}?page=${page}&limit=${limit}`,
		}),
	}),
});

export const {
	useGetChecklistsQuery,
	useGetChecklistQuery,
	useGetResponsesQuery,
	useGetResponseQuery,
	useGetAnswerQuery,
	useGetAnswersQuery,
	useUploadFilesMutation,
	useDeleteFileMutation,
	useGetEditingChecklistQuery,
	useGetEditingResponseQuery,
	useGetCommentsQuery,
	useGetMeQuery,
	useGetFoldersQuery,
	useGetFolderQuery,
	useGetHistoryFirstPageQuery,
	useGetHistoryNextPagesQuery,
} = api;
