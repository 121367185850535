import { useState, useEffect, useRef } from 'react';
import styles from './TypeSelectionPopup.module.scss';
import { ReactComponent as SingleSelectIcon } from './icons/single.svg';
import { ReactComponent as MultipleSelectIcon } from './icons/multiple.svg';
import { ReactComponent as TextIcon } from './icons/text.svg';
import { ReactComponent as NumberIcon } from './icons/number.svg';
import { ReactComponent as DateIcon } from './icons/date.svg';
import { ReactComponent as MediaIcon } from './icons/media.svg';
import { ReactComponent as ArrowDownIcon } from './icons/arrow_down.svg';
import { ReactComponent as ArrowRight } from './icons/chevron_right.svg';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import Switch from './Switch/Switch';

export default function TypeSelectionPopup({
	onSelection,
	selectedType,
}: {
	onSelection: (
		type:
			| 'singleSelect'
			| 'multipleSelect'
			| 'text'
			| 'number'
			| 'date'
			| 'media'
	) => void;
	selectedType:
		| 'singleSelect'
		| 'multipleSelect'
		| 'text'
		| 'number'
		| 'date'
		| 'media'
		| null;
}) {
	const [navigation, setNavigation] = useState<'selection' | 'saisie' | null>(
		null
	);
	const [isOpen, setIsOpen] = useState(false);
	const popupRef = useRef<HTMLDivElement>(null);
	const secondPopupRef = useRef<HTMLDivElement>(null);
	const [inputValue, setInputValue] = useState('');
	const questionTypes = [
		{ label: 'Sélection unique', value: 'singleSelect' },
		{ label: 'Sélection multiple', value: 'multipleSelect' },
		{ label: 'Texte', value: 'text' },
		{ label: 'Nombre', value: 'number' },
		{ label: 'Date', value: 'date' },
		{ label: 'Média', value: 'media' },
	];
	const [filteredQuestionTypes, setFilteredQuestionTypes] = useState<
		Array<{ label: string; value: string }>
	>([]);

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (
				popupRef.current &&
				!popupRef.current.contains(e.target as Node) &&
				secondPopupRef.current &&
				!secondPopupRef.current.contains(e.target as Node)
			) {
				setIsOpen(false);
				setNavigation(null);
				setInputValue('');
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSelection = (
		type:
			| 'singleSelect'
			| 'multipleSelect'
			| 'text'
			| 'number'
			| 'date'
			| 'media'
	) => {
		onSelection(type);
		setIsOpen(false);
		setNavigation(null);
		setInputValue('');
	};

	return (
		<div className={styles.typeSelectionContainer}>
			<button
				className={styles.typeSelectionButton}
				onClick={() => setIsOpen(true)}
			>
				<div className={styles.flex}>
					{selectedType === null ? (
						<>Sélectionnez un type de question</>
					) : selectedType === 'singleSelect' ? (
						<>
							<SingleSelectIcon />
							Sélection unique
						</>
					) : selectedType === 'multipleSelect' ? (
						<>
							<MultipleSelectIcon />
							Sélection multiple
						</>
					) : selectedType === 'text' ? (
						<>
							<TextIcon />
							Texte
						</>
					) : selectedType === 'number' ? (
						<>
							<NumberIcon />
							Nombre
						</>
					) : selectedType === 'date' ? (
						<>
							<DateIcon />
							Date
						</>
					) : (
						<>
							<MediaIcon />
							Média
						</>
					)}
				</div>
				<ArrowDownIcon />
			</button>
			{isOpen && (
				<div ref={popupRef} className={styles.typeSelectionPopup}>
					<div className={styles.header}>
						<h5>Type de question</h5>
						<input
							type="text"
							placeholder="Chercher un type de question"
							value={inputValue}
							className={styles.searchInput}
							onChange={(e) => {
								const value = e.target.value;
								setInputValue(value);
								if (value === '') {
									setFilteredQuestionTypes([]);
								} else {
									const filteredTypes = questionTypes.filter(
										(type) =>
											type.label.toLowerCase().includes(value.toLowerCase()) ||
											type.value.toLowerCase().includes(value.toLowerCase())
									);
									setFilteredQuestionTypes(filteredTypes);
								}
							}}
						/>
						{inputValue !== '' ? (
							<div className={styles.spacedFlex}>
								<ul>
									{filteredQuestionTypes.map((type) => (
										<li
											key={type.value}
											onClick={() =>
												handleSelection(
													type.value as
														| 'singleSelect'
														| 'multipleSelect'
														| 'text'
														| 'number'
														| 'date'
														| 'media'
												)
											}
										>
											<div className={styles.flex}>
												{type.value === 'singleSelect' && <SingleSelectIcon />}
												{type.value === 'multipleSelect' && (
													<MultipleSelectIcon />
												)}
												{type.value === 'text' && <TextIcon />}
												{type.value === 'number' && <NumberIcon />}
												{type.value === 'date' && <DateIcon />}
												{type.value === 'media' && <MediaIcon />}
												{type.label}
											</div>
											<Switch
												isOn={
													(type.value === 'singleSelect' &&
														selectedType === 'singleSelect') ||
													(type.value === 'multipleSelect' &&
														selectedType === 'multipleSelect') ||
													(type.value === 'text' && selectedType === 'text') ||
													(type.value === 'number' &&
														selectedType === 'number') ||
													(type.value === 'date' && selectedType === 'date') ||
													(type.value === 'media' && selectedType === 'media')
												}
											/>
										</li>
									))}
								</ul>
							</div>
						) : (
							<div className={styles.navigation}>
								<button
									className={`${styles.navButton} ${
										navigation === 'selection' ? styles.selectedNavButton : ''
									}`}
									onPointerOver={() => setNavigation('selection')}
								>
									Sélection
									<ArrowRight />
								</button>
								<button
									style={{ width: '100%' }}
									className={`${styles.navButton} ${
										navigation === 'saisie' ? styles.selectedNavButton : ''
									}`}
									onPointerOver={() => setNavigation('saisie')}
								>
									Saisie
									<ArrowRight />
								</button>
							</div>
						)}
					</div>
				</div>
			)}
			{inputValue === '' &&
				(navigation === 'selection' ? (
					<div ref={secondPopupRef} className={styles.finalPopup}>
						<div className={styles.title}>Sélection</div>
						<ul>
							<li
								className={
									selectedType === 'singleSelect' ? styles.selected : ''
								}
								onClick={() => handleSelection('singleSelect')}
							>
								<div className={styles.flex}>
									<SingleSelectIcon />
									Sélection unique
								</div>
								{selectedType === 'singleSelect' && <CheckIcon />}
							</li>
							<li
								className={
									selectedType === 'multipleSelect' ? styles.selected : ''
								}
								onClick={() => handleSelection('multipleSelect')}
							>
								<div className={styles.flex}>
									<MultipleSelectIcon />
									Sélection multiple
								</div>
								{selectedType === 'multipleSelect' && <CheckIcon />}
							</li>
							<li
								className={selectedType === 'date' ? styles.selected : ''}
								onClick={() => {
									handleSelection('date');
								}}
							>
								<div className={styles.flex}>
									<DateIcon />
									Date
								</div>
								{selectedType === 'date' && <CheckIcon />}
							</li>
						</ul>
					</div>
				) : (
					navigation === 'saisie' && (
						<div ref={secondPopupRef} className={styles.finalPopup}>
							<div className={styles.title}>Saisie</div>
							<ul>
								<li
									className={selectedType === 'media' ? styles.selected : ''}
									onClick={() => handleSelection('media')}
								>
									<div className={styles.flex}>
										<MediaIcon />
										Média
									</div>
									{selectedType === 'media' && <CheckIcon />}
								</li>
								<li
									className={selectedType === 'text' ? styles.selected : ''}
									onClick={() => handleSelection('text')}
								>
									<div className={styles.flex}>
										<TextIcon />
										Texte
									</div>
									{selectedType === 'text' && <CheckIcon />}
								</li>
								<li
									className={selectedType === 'number' ? styles.selected : ''}
									onClick={() => handleSelection('number')}
								>
									<div className={styles.flex}>
										<NumberIcon />
										Nombre
									</div>
									{selectedType === 'number' && <CheckIcon />}
								</li>
							</ul>
						</div>
					)
				))}
		</div>
	);
}
