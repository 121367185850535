export function formatTimeDifference(date1: Date, date2: Date) {
	const timestamp1 = new Date(date1).getTime();
	const timestamp2 = new Date(date2).getTime();
	const differenceInMillis = Math.abs(timestamp1 - timestamp2);
	const differenceInSeconds = Math.floor(differenceInMillis / 1000);
	const differenceInMinutes = Math.floor(differenceInSeconds / 60);
	const differenceInHours = Math.floor(differenceInMinutes / 60);
	const differenceInDays = Math.floor(differenceInHours / 24);

	if (differenceInDays > 0) {
		return `${differenceInDays} ${differenceInDays > 1 ? 'jours' : 'jour'}`;
	} else if (differenceInHours > 0) {
		return `${differenceInHours} ${differenceInHours > 1 ? 'heures' : 'heure'}`;
	} else if (differenceInMinutes > 0) {
		return `${differenceInMinutes} ${
			differenceInMinutes > 1 ? 'minutes' : 'minute'
		}`;
	} else {
		return `${differenceInSeconds} ${
			differenceInSeconds > 1 ? 'secondes' : 'seconde'
		}`;
	}
}
