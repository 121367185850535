import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';

const baseUrl = config.baseUrl;

export const createApiWithBaseQuery = (reducerPath: string) =>
	createApi({
		reducerPath,
		baseQuery: fetchBaseQuery({
			baseUrl,
			prepareHeaders: async (headers) => {
				const token = localStorage.getItem('token');
				if (token) {
					headers.set('Authorization', `Bearer ${token}`);
				}
				return headers;
			},
		}),
		endpoints: () => ({}),
	});
