import styles from './ResponsesMenu.module.scss';
import {
	Response,
	Answer as Answers,
	Question,
	Checklist as ChecklistType,
} from '../../sharedTypes';
import { ReactComponent as PlayIcon } from '../icons/play_circle_filled.svg';
import { formatTimeDifference } from '../../utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';
import { useNavigation } from '../../NavigationContext';
import { useGetAnswersQuery } from '../../store/api';
import { useState, useEffect } from 'react';
import Avatar from 'components/Avatar/Avatar';
import CoverImage from 'components/shared/CoverImage/CoverImage';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

export default function ResponsesMenu({
	currentAnswers,
	checklistId,
	responses,
	setNavigation,
	checklist,
}: {
	currentAnswers?: { [key: string]: Answers };
	checklistId: string;
	responses: Response[];
	setNavigation: (navigation: string) => void;
	checklist?: ChecklistType;
}) {
	const isIntegrated = useSelector(
		(state: RootState) => state.user.isIntegrated
	);
	const { data: answers, refetch } = useGetAnswersQuery(checklistId, {
		skip: !checklistId,
	});
	const { openedResponse, setOpenedResponse, setResponseMode } =
		useNavigation();

	const [lastModifiedTime, setLastModifiedTime] = useState(
		formatTimeDifference(
			new Date(),
			new Date(checklist?.lastModified?.timestamp || new Date())
		)
	);

	useEffect(() => {
		if (currentAnswers) {
			refetch();
		}
	}, [currentAnswers, refetch]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setLastModifiedTime(
				formatTimeDifference(
					new Date(),
					new Date(checklist?.lastModified?.timestamp || new Date())
				)
			);
		}, 10000);

		return () => clearInterval(intervalId);
	}, [checklist?.lastModified?.timestamp]);

	return (
		<div className={styles.menu}>
			{!isIntegrated && (
				<div className={styles.navButtons}>
					<button className={styles.selected}>Réponses</button>
					<button
						onClick={() => {
							setOpenedResponse(null);
							setResponseMode(false);
							setNavigation(`checklist_${checklistId}`);
						}}
					>
						Éditer la Checklist
					</button>
				</div>
			)}
			<div
				onClick={() => setNavigation('checklist')}
				className={styles.navigationHeader}
			>
				<CoverImage id={checklistId} coverImage={checklist?.coverImage} />
				<div className={styles.column}>
					<h5>{checklist?.title}</h5>
					<div className={styles.lastEdit}>
						<Avatar
							small
							name={`${checklist?.lastModified?.user?.firstName} ${checklist?.lastModified?.user?.lastName}`}
						/>
						{lastModifiedTime === '0 seconde'
							? "modifié à l'instant"
							: `modifié il y a ${lastModifiedTime}`}
					</div>
				</div>
			</div>
			{responses.length > 0 && (
				<div className={styles.newResponse}>Nouvelles réponses</div>
			)}
			{responses.map((response, index) => (
				<button
					onClick={() => {
						setNavigation(`checklist_${checklistId}`);
						setOpenedResponse(response);
					}}
					key={index}
					className={`${styles.menuItem} ${
						openedResponse?.responseId === response.responseId
							? styles.selected
							: ''
					}`}
				>
					<div className={styles.modifiedBy}>
						{response.modifiedBy?.slice(0, 2).map((user) => (
							<Avatar
								key={user.userId}
								name={`${user.firstName} ${user.lastName}`}
								id={user.userId}
								small
								column
							/>
						))}
						{response.modifiedBy && response.modifiedBy.length > 2 && (
							<Avatar
								name={`+ ${response.modifiedBy.length - 2}`}
								color="grey"
								small
								column
							/>
						)}
					</div>
					<div className={styles.column}>
						<div className={styles.tabBarItems}>
							{format(new Date(response.timestamp), 'd MMM yyyy, HH:mm', {
								locale: fr,
							})}
						</div>
						<div className={styles.title}>
							{`${(() => {
								const firstQuestion = (
									response.state.chapters?.[0 as number] as {
										questions: Question[];
									}
								)?.questions?.[0];
								const firstAnswer =
									answers && answers[index]?.state[firstQuestion?.questionId];
								if (
									firstAnswer &&
									(firstQuestion?.type === 'text' ||
										firstQuestion?.type === 'singleSelect')
								) {
									return firstAnswer;
								}
								return `Réponse #${response.index}`;
							})()}`}
						</div>
						<div className={styles.remaining}>
							{answers && (
								<ProgressBar
									chapters={response.state.chapters}
									answers={answers[index]?.state}
									noPadding
									width="10rem"
								/>
							)}
						</div>
					</div>
					{openedResponse?.responseId === response.responseId && (
						<div className={styles.playIcon}>
							<PlayIcon />
						</div>
					)}
				</button>
			))}
		</div>
	);
}
