import React, {
	useCallback,
	useState,
	useEffect,
	useRef,
	useMemo,
	useLayoutEffect,
} from 'react';
import {
	VariableSizeList as List,
	ListOnItemsRenderedProps,
} from 'react-window';
import {
	useGetHistoryFirstPageQuery,
	useGetHistoryNextPagesQuery,
} from '../../../store/api';
import styles from './History.module.scss';
import Avatar from '../../Avatar/Avatar';
import { UUID, HistoryEvent } from 'sharedTypes';
import { format, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const ITEMS_PER_PAGE = 20;

const HistoryItem = React.memo(
	({
		event,
		index,
		style,
		setItemSize,
		isFirstEventOfDay,
	}: {
		event: {
			title: string;
			oldValue: string;
			newValue: string;
			date: Date;
			user: { firstName: string; lastName: string };
		};
		index: number;
		style: React.CSSProperties;
		setItemSize: (index: number, size: number) => void;
		isFirstEventOfDay: boolean;
	}) => {
		const itemRef = useRef<HTMLDivElement>(null);

		useLayoutEffect(() => {
			if (itemRef.current) {
				const height = itemRef.current.getBoundingClientRect().height;
				setItemSize(index, height);
			}
		}, [event, index, setItemSize]);

		if (!event) return null;

		return (
			<div
				ref={itemRef}
				className={styles.historyItem}
				style={{ ...style, height: 'auto' }}
			>
				{isFirstEventOfDay && (
					<div className={styles.date}>
						{format(new Date(event.date), 'd MMMM yyyy', {
							locale: fr,
						}).toUpperCase()}
					</div>
				)}
				<div className={styles.historyInfo}>
					<div className={styles.content}>
						<div className={styles.title}>
							<span>{event.title}</span>
							<span>{format(new Date(event.date), 'HH:mm')}</span>
						</div>
						{event.oldValue ? (
							<>
								<div className={styles.oldModif}>
									<span>{event.oldValue}</span>
								</div>
								<div className={styles.newModif}>
									<span>{event.newValue}</span>
								</div>
							</>
						) : (
							event.newValue && (
								<div className={styles.changesContent}>{event.newValue}</div>
							)
						)}
						<div className={`${styles.user} ${styles.userPadding}`}>
							<Avatar
								medium
								id={event.user.firstName + event.user.lastName}
								name={`${event.user.firstName} ${event.user.lastName}`}
							/>
							<div className={styles.userColumn}>
								<div className={`${styles.userName} ${styles.historyUser}`}>
									{`${event.user.firstName} ${event.user.lastName}`}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

const History = ({
	close,
	checklistId,
}: {
	close: () => void;
	checklistId: UUID;
}) => {
	const [page, setPage] = useState(1);
	const [allItemsLoaded, setAllItemsLoaded] = useState(false);
	const [firstPageEvents, setFirstPageEvents] = useState<HistoryEvent[]>([]);
	const [nextPagesEvents, setNextPagesEvents] = useState<HistoryEvent[]>([]);
	const [allEvents, setAllEvents] = useState<HistoryEvent[]>([]);
	const [listHeight, setListHeight] = useState(400);
	const containerRef = useRef<HTMLDivElement>(null);
	const [itemSizes, setItemSizes] = useState<{ [key: number]: number }>({});
	const listRef = useRef<List>(null);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const { data: firstPageData, isLoading: isFirstPageLoading } =
		useGetHistoryFirstPageQuery({
			checklistId,
			limit: ITEMS_PER_PAGE,
		});

	const { data: nextPagesData, isFetching: isNextPagesFetching } =
		useGetHistoryNextPagesQuery(
			{
				checklistId,
				page,
				limit: ITEMS_PER_PAGE,
			},
			{ skip: page === 1 }
		);

	useEffect(() => {
		if (firstPageData) {
			setFirstPageEvents(firstPageData.events);
			if (firstPageData.currentPage === firstPageData.totalPages) {
				setAllItemsLoaded(true);
			} else {
				setPage(2);
			}
		}
	}, [firstPageData]);

	useEffect(() => {
		if (nextPagesData) {
			setNextPagesEvents((previous) => [...previous, ...nextPagesData.events]);
			if (nextPagesData.currentPage === nextPagesData.totalPages) {
				setAllItemsLoaded(true);
			}
			setIsLoadingMore(false);
		}
	}, [nextPagesData]);

	useEffect(() => {
		setAllEvents([...firstPageEvents, ...nextPagesEvents]);
	}, [firstPageEvents, nextPagesEvents]);

	const loadMore = useCallback(() => {
		if (!isNextPagesFetching && !allItemsLoaded && !isLoadingMore) {
			setIsLoadingMore(true);
			setPage((prev) => prev + 1);
		}
	}, [isNextPagesFetching, allItemsLoaded, isLoadingMore]);

	const transformEvent = useCallback((event: HistoryEvent) => {
		const { timestamp, userFirstName, userLastName } = event;
		let title = '';
		let oldValue = '';
		let newValue = '';

		switch (event.type) {
			// Checklist
			case 'checklistTitle':
				title = `#${event.old} → ${event.new}`;
				break;

			// Chapter
			case 'chapterCreate':
				title = `Chapitre créé: ${event.new?.title}`;
				break;
			case 'chapterDelete':
				title = `Chapitre supprimé: ${event.title}`;
				break;
			case 'chapterOrder':
				title = 'Ordre des chapitres modifié';
				oldValue = event.old?.join(', ') || '';
				newValue = event.new?.join(', ') || '';
				break;

			// Question
			case 'questionCreate':
				title = `Question créée: ${event.new?.title}`;
				newValue = event.new?.type ? `Type: ${event.new.type}` : '';
				break;
			case 'questionDelete':
				title = `Question supprimée: ${event.title}`;
				break;
			case 'questionOrder':
				title = 'Ordre des questions modifié';
				oldValue = event.old?.join(', ') || '';
				newValue = event.new?.join(', ') || '';
				break;
			case 'questionDuplicate':
				title = `Question dupliquée: ${event.title}`;
				break;

			// Settings
			case 'singleSelectSettings':
			case 'multipleSelectSettings':
				title = 'Choix modifiés';
				oldValue = event.old?.options
					? `Ancien: ${event.old.options.join(', ')}`
					: '';
				newValue = event.new?.options
					? `Nouveau: ${event.new.options.join(', ')}`
					: '';
				break;
			case 'numberSettings':
				title = 'Type de question modifié';
				oldValue = event.old?.mode ? `Ancien: ${event.old.mode}` : '';
				newValue = event.new?.mode ? `Nouveau: ${event.new.mode}` : '';
				break;
			case 'textSettings':
				title = 'Paramètres texte modifiés';
				oldValue = event.old?.criticalValues
					? `Ancien: ${event.old.criticalValues.join(', ')}`
					: '';
				newValue = event.new?.criticalValues
					? `Nouveau: ${event.new.criticalValues.join(', ')}`
					: '';
				break;
			case 'mediaSettings':
				title = 'Type de média modifié';
				oldValue = event.old?.mediaType
					? `Ancien: ${event.old.mediaType}, ${event.old.maxFiles} max`
					: '';
				newValue = event.new?.mediaType
					? `Nouveau: ${event.new.mediaType}, ${event.new.maxFiles} max`
					: '';
				break;
			case 'dateSettings':
				title = 'Paramètres date modifiés';
				oldValue =
					event.old?.period !== undefined
						? event.old.period
							? 'Période'
							: 'Date unique'
						: '';
				newValue =
					event.new?.period !== undefined
						? event.new.period
							? 'Période'
							: 'Date unique'
						: '';
				break;
			case 'questionRules':
				title = 'Règles de la question modifiées';
				oldValue = event.old?.length ? `${event.old.length} règles` : '';
				newValue = event.new?.length ? `${event.new.length} règles` : '';
				break;
			default:
				throw new Error(`Type d'événement non géré : ${(event as any).type}`);
		}

		return {
			title,
			oldValue: oldValue || '',
			newValue: newValue || '',
			date: timestamp,
			user: { firstName: userFirstName, lastName: userLastName },
		};
	}, []);

	const memoizedEvents = useMemo(
		() => allEvents.map(transformEvent),
		[allEvents, transformEvent]
	);

	const setItemSize = useCallback((index: number, size: number) => {
		setItemSizes((prev) => {
			if (prev[index] === size) return prev;
			return { ...prev, [index]: size };
		});
	}, []);

	const getItemSize = useCallback(
		(index: number) => {
			return itemSizes[index] || 100;
		},
		[itemSizes]
	);

	const Item = useCallback(
		({ index, style }: { index: number; style: React.CSSProperties }) => {
			const event = memoizedEvents[index];
			const isFirstEventOfDay =
				index === 0 ||
				!isSameDay(
					new Date(allEvents[index - 1].timestamp),
					new Date(event.date)
				);

			return (
				<HistoryItem
					event={event}
					index={index}
					style={style}
					setItemSize={setItemSize}
					isFirstEventOfDay={isFirstEventOfDay}
				/>
			);
		},
		[memoizedEvents, allEvents, setItemSize]
	);

	useEffect(() => {
		const updateHeight = () => {
			if (containerRef.current) {
				setListHeight(containerRef.current.clientHeight);
			}
		};

		updateHeight();
		window.addEventListener('resize', updateHeight);

		return () => window.removeEventListener('resize', updateHeight);
	}, []);

	const handleItemsRendered = useCallback(
		({ overscanStopIndex }: ListOnItemsRenderedProps) => {
			if (
				!isNextPagesFetching &&
				!allItemsLoaded &&
				!isLoadingMore &&
				overscanStopIndex >= allEvents.length - 5
			) {
				loadMore();
			}
		},
		[
			isNextPagesFetching,
			allItemsLoaded,
			isLoadingMore,
			allEvents.length,
			loadMore,
		]
	);

	useEffect(() => {
		if (listRef.current) {
			listRef.current.resetAfterIndex(0);
		}
	}, [itemSizes]);

	return (
		<div className={styles.history}>
			<div className={styles.flex}>
				<h4>Historique</h4>
				<button className={styles.noBorderButton} onClick={close}>
					<CloseIcon />
				</button>
			</div>
			<div className={styles.historyList} ref={containerRef}>
				{isFirstPageLoading ? (
					<div>Chargement...</div>
				) : (
					<List
						ref={listRef}
						height={listHeight}
						itemCount={allEvents.length}
						itemSize={getItemSize}
						width="100%"
						className={styles.list}
						onItemsRendered={handleItemsRendered}
					>
						{Item}
					</List>
				)}
				{isLoadingMore && <div>Chargement de plus d'événements...</div>}
			</div>
		</div>
	);
};

export default React.memo(History);
