import React from 'react';
import Switch from '../../Switch/Switch';
import {Question as QuestionType} from '../../../sharedTypes';
import styles from './Settings.module.scss';

export default function DateSettings({
	question,
	updateQuestion,
}: {
	question: QuestionType;
	updateQuestion: (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => void;
}) {
	return (
		<div className={styles.dateSettings}>
			<div
				onClick={() => {
					updateQuestion(question, {
						date: {
							period: !question.date?.period,
						},
					});
				}}
				className={styles.column}
			>
				<div className={styles.spacedFlex}>
					<div className={styles.button}>CHOIX DE PÉRIODES SPÉCIFIQUES</div>
					<Switch isOn={question.date?.period} />
				</div>
				<p>
					Permet de proposer une plage de dates directement dans le mode de
					réponse
				</p>
			</div>
		</div>
	);
}
