import {useRef, useEffect, useState} from 'react';
import {getSocket} from '../../store/socket';
import {ReactComponent as MoreIcon} from '../icons/more.svg';
import {ReactComponent as DuplicateIcon} from '../icons/duplicate.svg';
import {Question as QuestionType} from '../../sharedTypes';
import styles from './DraggableNavigation.module.scss';

export default function DeletePopup({
	checklistId,
	question,
}: {
	checklistId: string;
	question: QuestionType;
}) {
	const deletePopupRef = useRef<HTMLDivElement>(null);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (
				deletePopupRef.current &&
				!deletePopupRef.current.contains(e.target as Node)
			) {
				setOpenDeletePopup(false);
			}
		};
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleDeleteQuestion = (question: QuestionType) => {
		getSocket()?.emit('deleteQuestion', {
			checklistId,
			chapterId: question.chapterId,
			questionId: question.questionId,
		});
		setOpenDeletePopup(false);
	};

	const handleDuplicateQuestion = (question: QuestionType) => {
		getSocket()?.emit('duplicateQuestion', {
			checklistId,
			chapterId: question.chapterId,
			questionId: question.questionId,
		});
	};

	return (
		<>
			<MoreIcon onClick={() => setOpenDeletePopup(true)} />
			{openDeletePopup && (
				<div ref={deletePopupRef} className={styles.deletePopup}>
					<button
						onClick={() => handleDuplicateQuestion(question)}
						className={styles.duplicate}
					>
						<DuplicateIcon />
						Dupliquer cette question
					</button>
					<button
						onClick={() => handleDeleteQuestion(question)}
						className={styles.delete}
					>
						Supprimer cette question
					</button>
				</div>
			)}
		</>
	);
}
