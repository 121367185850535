import React, { useState } from 'react';
import styles from './Counter.module.scss';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../icons/minus.svg';

interface CounterProps {
	min: number;
	max: number;
	onChange: (value: number) => void;
	value: number;
}

const Counter: React.FC<CounterProps> = ({ min, max, onChange, value }) => {
	const [count, setCount] = useState(value);

	const handleDecrement = () => {
		if (count > min) {
			const newValue = count - 1;
			setCount(newValue);
			onChange(newValue);
		}
	};

	const handleIncrement = () => {
		if (count < max) {
			const newValue = count + 1;
			setCount(newValue);
			onChange(newValue);
		}
	};

	return (
		<div className={styles.counter}>
			<button onClick={handleDecrement} disabled={count === min}>
				<MinusIcon />
			</button>
			<span className={styles.count}>{count}</span>
			<button onClick={handleIncrement} disabled={count === max}>
				<PlusIcon />
			</button>
		</div>
	);
};

export default Counter;
